import React from "react";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import media from "styled-media-query";

const PopOver = ({ text, id }) => {
  return (
    <>
      <Icon data-tip data-for={id}>
        ?
      </Icon>
      <ReactTooltip id={id} type="warning" effect="solid" aria-haspopup="true">
        <Text>{text}</Text>
      </ReactTooltip>
    </>
  );
};

const Icon = styled.span`
  height: 24px;
  width: 24px;
  border-radius: calc(24px / 2);
  margin-left: 10px;
  color: #999;
  border: 1px solid #999;
  transition: all 0.3s ease;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;

  ${media.lessThan("medium")`
    display: none;
  `}

  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
    border: 1px solid ${({ theme }) => theme.colors.secondary};
  }
`;

const Text = styled.p`
  max-width: 300px;
  font-size: 0.8rem;
`;

export default PopOver;
