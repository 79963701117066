import React, { createRef, useEffect , useState} from "react";
import styled from "styled-components";
import media from "styled-media-query";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import MainLayout from "layouts/Main.layout";

import { ReactComponent as LogoIcon } from "assets/img/loga_logo_2.svg";

import { ReactComponent as DocIcon } from "assets/img/doc_icon.svg";
import ShadowButton from "components/shadow-button/ShadowButton.component";
import { useGlobalState } from "state/ContextProvider";
import { useLocationState } from "state/LocationProvider";
import LogaModal from "components/loga-modal/LogaModal.component";
import alert from "assets/img/alert.svg";

function Protocol() {
  const { name, protocolo } = useGlobalState();
  const { establishment } = useLocationState();

  const textarea = createRef(null);
  
  const { addToast } = useToasts();

  useEffect(() => {
    window.dataLayer.push({
      event: "pageview",
      page: {
        url:
          document.location.origin +
          document.location.pathname +
          document.location.search,
        title: "Protocolo",
      },
    });
  }, []);

  useEffect(() => {
    if (!name.value) {
      backHome();
    }
  }, []);

  const backHome = () => {
    window.location.href = "/";
  };

  const onClickCopy = () => {
    textarea.current.select();
    document.execCommand("copy");

    addToast("Código de protocolo copiado", {
      appearance: "info",
    });

    // setToggleModalErr(true);
    //    setErrMessage({
    //     ...errMessage,
    //     title:"Atenção",
    //     message:"Código de protocolo copiado"
    //   });
  };


  return (
    <>
    <MainLayout title="Protocolo">
      <ContainerStyle>
        <InfoWrapperStyle>
          <LeftCol>
            <Title>
              Parabéns {name.value || ""}, agora falta pouco para você ser um
              logado :D
            </Title>
            <Describe>
              Estamos felizes em iniciar essa experiência juntos. Em breve nossa
              equipe vai entrar em contato com você e confirmar alguns dados e
              agendar sua instalação.
            </Describe>

            {establishment !== "negocio" && (
              <>
                <ProtocolContainer>
                  <Subtitle>Protocolo do seu pedido</Subtitle>

                  <ProtocolWrap>
                    <TextArea value={protocolo} ref={textarea}></TextArea>
                    <IconButton onClick={onClickCopy}>
                      <DocIcon />
                    </IconButton>
                  </ProtocolWrap>

                  <Small>
                    Com esse número você pode consultar seu pedido junto a nossa
                    equipe. Então guarde-o :D.
                  </Small>
                </ProtocolContainer>
              </>
            )}
          </LeftCol>

          <RightCol>
            <LogoIcon />
          </RightCol>
        </InfoWrapperStyle>

        <Empty />

        <ShadowButton onClick={backHome}>Voltar para a Home</ShadowButton>
      </ContainerStyle>
    </MainLayout>
    </>
  );
}

const ContainerStyle = styled.section`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProtocolContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  max-width: 401px;
  flex-direction: column;
  margin: 2.3rem 0;

  ${media.lessThan("medium")`
    max-width: 100%;
  `}
`;

const InfoWrapperStyle = styled.section`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 3rem;
  width: 100%;

  ${media.lessThan("medium")`
    flex-direction: column;
  `}
`;

const TextArea = styled.textarea`
  margin: 0px;
  width: 100%;
  height: 32px;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  background-color: transparent;
  border: none;
  resize: none;
  box-shadow: 0 0 0 0;
  border: 0 none;
  outline: 0;
  overflow: hidden;

  &:focus {
    border: none;
    box-shadow: 0 0 0 0;
    border: 0 none;
    outline: 0;
  }
`;

const IconButton = styled.button`
  height: auto;
  width: auto;
  padding: 0;
  margin: 0;
`;

const LeftCol = styled.article`
  min-width: 300px;
  order: 1;
  flex: 1.3;

  ${media.lessThan("medium")`
    margin-left: 0;
    min-width: auto;
    max-width: 100%;
    order: 2;
  `}
`;

const Subtitle = styled.h3`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.secondary};
  line-height: 1;
  text-align: left;

  ${media.lessThan("medium")`
    font-size: 20px;
    text-align: center;
  `}
`;

const RightCol = styled.section`
  text-align: center;
  padding: 2rem;
  order: 2;
  flex: 1;
  min-width: 300px;

  & svg {
    width: 100%;
    height: auto;
    max-width: 300px;
  }

  ${media.lessThan("medium")`
    order: 1;
    display: flex;
    justify-content: center;
    align-items: center;
      & svg {
        width: 200px;
      }
  `}
`;

const Title = styled.h1`
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.compos};
  line-height: 1.3;
`;

const Describe = styled.p`
  font-size: 1.2rem;
  font-family: "Nunito";
  color: ${({ theme }) => theme.colors.primary};
  margin-top: 1.5rem;
  line-height: 1.2;
  position: relative;
`;

const ProtocolWrap = styled.div`
  padding: 15px 20px;
  background-color: ${({ theme }) => theme.colors.compos};
  border-radius: 6px;
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 1.3rem;
  color: ${({ theme }) => theme.colors.white};
  justify-content: space-between;
`;

const Small = styled.small`
  margin-top: 1rem;
  display: block;
  font-family: "Nunito";
  font-size: 0.9rem;
  color: ${({ theme }) => theme.colors.secondary};
`;

const Empty = styled.div`
  flex: 1;
  height: 2rem;
`;


export default Protocol;
