import React from "react";
import styled from "styled-components";
import FadeIn from "react-fade-in";

import { formatBRL } from "helpers/formatCurrency";
import Modal from "components/modal/Modal.component";

const PlanSummaryItem = ({ plan, showLink = false }) => {
  const [showModal, setShowModal] = React.useState(false);

  const getPlanType = () => {
    if (plan.is_tv) return "Televisão";

    if (plan.telefonia) return "Telefone - Fixo";

    if (plan.velocidade) return "Internet";

    if (plan?.grupos == "50") {
      return "Celular";
    }
  };

  const getDetails = () => {
    if (plan.is_tv) return ``;

    if (plan.telefonia) return "ILIMITADO";

    if (plan.velocidade) return `${plan.velocidade} Mega de velocidade`;

    if (plan?.grupos == "50") {
      return `Voz ilimitado, SMS ilimitado`;
    }
  };

  const handleOpenModal = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  return (
    <FadeIn>
      <Modal
        open={showModal}
        title={plan.nome}
        onClose={() => setShowModal(false)}
      >
        <Describe dangerouslySetInnerHTML={{ __html: plan.regulamento }} />
      </Modal>
      <ProductSummaryStyle>
        <ProductTypeWrapper>
          <ProductName>{getPlanType()}</ProductName>
          <ProductValue>{formatBRL(plan.preco)}</ProductValue>
        </ProductTypeWrapper>
        <ProductListDescription>
          <DetailsWrapper>
            <LabelItem>{plan.nome}</LabelItem>

            <LabelItemValue>{getDetails()}</LabelItemValue>
          </DetailsWrapper>

          {showLink && (
            <Regulamento href="#" onClick={handleOpenModal}>
              Termo de uso
            </Regulamento>
          )}
        </ProductListDescription>
      </ProductSummaryStyle>
    </FadeIn>
  );
};

const ProductSummaryStyle = styled.section`
  align-self: stretch;
  margin: 0.6rem 0;
  position: relative;
`;

const DetailsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
`;

const LabelItem = styled.span`
  margin: 5px;
  font-size: 0.9rem;
  font-family: "Nunito";
  font-weight: bold;
  color: ${({ theme }) => theme.colors.secondary};
`;

const LabelItemValue = styled(LabelItem)`
  font-size: 0.9rem;
  font-family: "Nunito";
  color: #656565;
  font-weight: 400;
`;

const Describe = styled.p`
  font-size: 0.8rem !important;
  font-family: "Nunito";

  & p {
    text-align: justify;
    line-height: 1rem !important;
    font-size: 0.8rem !important;
    font-family: "Nunito";
  }
`;

const ProductTypeWrapper = styled.p`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #e6e6e6;
  padding: 10px;
`;

const ProductName = styled.span`
  font-size: 1rem;
  font-family: "Nunito";
  color: ${({ theme }) => theme.colors.secondary};
`;

const ProductValue = styled(ProductName)`
  color: ${({ theme }) => theme.colors.primary};
`;

const ProductListDescription = styled.ul`
  width: 100%;
  padding: 0.8rem;
  display: flex;
  list-style: none;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;

  & span:first-child {
    font-size: 0.9rem;
    font-family: "Nunito";
    font-weight: bold;
    color: ${({ theme }) => theme.colors.secondary};
  }

  & span:last-child {
    font-size: 0.9rem;
    font-family: "Nunito";
    color: #656565;
  }
`;

const Regulamento = styled.a`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 0.9rem;
  font-family: "Nunito";
  text-decoration: underline;
  margin: 5px;
`;

export default PlanSummaryItem;
