export const colors = {
  primary: "#EE9032",
  secondary: "#1A284A",
  compos: "#00ADB8",
  white: "#fff",
  black: "#000",
  input: "#EDEDED",
  shadow: "0px 3px 6px #00000029",
  lighter: "#F8F8F8",
  red: "#f44336",
};

const theme = {
  colors,
};

export default theme;
