import React, { useState, useEffect, useCallback, forwardRef } from "react";
import styled from "styled-components";
import media from "styled-media-query";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import { useLocationState } from "state/LocationProvider";

import MainLayout from "layouts/Main.layout";
import Step from "components/step/Step.component";
import { useGlobalState } from "state/ContextProvider";
import Loading from "components/loading/Loading.component";
import Card, { CardFooter, CardHeader } from "components/card/Card.component";
import { formatBRL } from "helpers/formatCurrency";
import { getPlansByIbge } from "services/plans";
import CardList from "components/card-list/CardList.component";
import ChannelsModal from "components/channels-modal/ChannelsModal.component";

import BoxMobileSelecteds from "./components/BoxMobileSelecteds.component";
import BoxDesktopSelecteds from "./components/BoxDesktopSelecteds.component";
import LogaModal from "components/loga-modal/LogaModal.component";

function MountPackage() {
  const [plans, setPlans] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [tvPlan, setTvPlan] = useState(null);

  const { ibge, combo, isBusiness, setCombo, cep } = useGlobalState();
  const { setSelectedBuilding } = useLocationState();

  const navigation = useHistory();
  const [toggleModalErr,setToggleModalErr] = useState(false);
  const [errMessage,setErrMessage] = useState({
    title:"",
    message:""
  });

  const fetchData = useCallback(async () => {
    try {
      setInitialized(false);

      let tipoPessoa = isBusiness ? 0 : 1;

      let zipNumber = cep.value.replace("-","");

      const cityPlans = await getPlansByIbge(ibge, tipoPessoa, zipNumber);

      if (cityPlans.message) {
        return navigation.push("/");
      }

      setPlans(cityPlans);
    } catch (err) {
      setToggleModalErr(true);
       setErrMessage({
        ...errMessage,
        title:err?.response?.data?.message || " Opss... Falha na comunicação.",
        message:"Falha ao se comunicar, tente novamente."
      });
    } finally {
      setInitialized(true);
    }
  }, [ibge]);

  useEffect(() => {
    window.dataLayer.push({
      event: "pageview",
      page: {
        url:
          document.location.origin +
          document.location.pathname +
          document.location.search,
        title: "Selecione seus produtos",
      },
    });

    setCombo([]);
    setSelectedBuilding(null);

    if (!ibge) {
      return navigation.push("/");
    }
    fetchData();
  }, [fetchData]);

  const selectedInternetPlan = () => {
    return combo.some((selected) => !!selected.velocidade);
  };

  const toggleModal = () => setToggle((old) => !old);

  const buildCardsPlanTv = (plan) => {
    const regex = /adicional|adicionais/gi;
    const collapseAll = combo.some(
      (product) => product.grupos.includes("30") && !product.nome.match(regex)
    );
    const selected = combo.some((product) => product.id === plan.id);

    return (
      <Card
        key={plan.id}
        plan={plan}
        collapseAllProp={collapseAll}
        selected={selected}
      >
        <CardHeader
          collapseAllProp={collapseAll}
          title={plan.nome}
          primaryInformation={``}
        />

        <CardFooter
          collapseAllProp={collapseAll}
          monthValue={formatBRL(plan.preco)}
          onClickDetails={(e) => {
            e.stopPropagation();
            e.preventDefault();
            toggleModal();
            setTvPlan(plan.nome);
          }}
          period={plan.promo_periodo}
        />
      </Card>
    );
  };

  const buildCardInternet = (plan) => {
    const collapseAll = combo.some(
      (product) =>
        product.grupos.includes("11") || product.grupos.includes("10")
    );
    const selected = combo.some((product) => product.id === plan.id);

    return (
      <Card
        key={plan.id}
        plan={plan}
        collapseAllProp={collapseAll}
        selected={selected}
      >
        <CardHeader
          collapseAllProp={collapseAll}
          title={plan.nome}
          primaryInformation={`${plan.velocidade}Mega`}
        />

        <CardFooter
          collapseAllProp={collapseAll}
          monthValue={formatBRL(plan.preco)}
          period={plan.promo_periodo}
        />
      </Card>
    );
  };

  const buildCardTelefonia = (plan) => {
    const collapseAll = combo.some((product) => product.grupos.includes("20"));
    const selected = combo.some((product) => product.id === plan.id);

    return (
      <Card
        key={plan.id}
        plan={plan}
        collapseAllProp={collapseAll}
        selected={selected}
      >
        <CardHeader
          collapseAllProp={collapseAll}
          title={plan.nome}
          primaryInformation="Ilimitado"
        />

        <CardFooter
          monthValue={formatBRL(plan.preco)}
          collapseAllProp={collapseAll}
          period={plan.promo_periodo}
        />
      </Card>
    );
  };

  /**
   *
   * @param {{
   * nome: string
   * }} plan
   */
  const buildCardCelular = (plan) => {
    const match = /\d+gb/gim.exec(plan.nome);

    const collapseAll = combo.some((product) => product.grupos.includes("50"));
    const selected = combo.some((product) => product.id === plan.id);

    if (!match) {
      return (
        <Card
          key={plan.id}
          plan={plan}
          collapseAllProp={collapseAll}
          selected={selected}
        >
          <CardHeader
            collapseAllProp={collapseAll}
            title={plan.nome}
            primaryInformation={undefined}
            secondaryInformation="+Voz ilimitada"
          />

          <CardFooter
            monthValue={formatBRL(plan.preco)}
            collapseAllProp={collapseAll}
            period={plan.promo_periodo}
          />
        </Card>
      );
    }

    const index = match.index;
    const title = plan.nome.substring(0, index - 1);

    const gb = plan.nome.slice(index, index + 3).replace(/\D/g, "");

    return (
      <Card
        key={plan.id}
        plan={plan}
        collapseAllProp={collapseAll}
        selected={selected}
      >
        <CardHeader
          collapseAllProp={collapseAll}
          title={title}
          primaryInformation={`${gb}GB`}
          secondaryInformation="+Voz ilimitada"
        />

        <CardFooter
          monthValue={formatBRL(plan.preco)}
          collapseAllProp={collapseAll}
          period={plan.promo_periodo}
        />
      </Card>
    );
  };

  if (!initialized) {
    return (
      <MainLayout title="Selecione seus produtos">
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      </MainLayout>
    );
  }

  if (initialized && !plans) {
    return (
      <MainLayout title="Selecione seus produtos">
        <WrapperRow>
          <Container>
            <Step title="Selecione seus produtos" number={3} />

            <Loading />
          </Container>
        </WrapperRow>
      </MainLayout>
    );
  }

  const handleToggleErr = () => setToggleModalErr((old) => !old);

  return (
    <>
    <LogaModal toggle={toggleModalErr} handleTogle={handleToggleErr}>
        <Wrapper>
          <Image src={alert} alt="Mensagem de alerta"/>

          <Title>
            {errMessage.title}
          </Title>

          <Text>
            {errMessage.message}
          </Text>

          <ButtonAccept onClick={handleToggleErr}>Fechar</ButtonAccept>
        </Wrapper>
    </LogaModal>
    <MainLayout title="Selecione seus produtos">
      {toggle && (
        <ChannelsModal toggle={toggle} onClose={toggleModal} tvPlan={tvPlan} />
      )}
      <WrapperRow>
        <Container>
          <Step title="Escolha seus produtos Loga" number={3} />

          {!!plans?.internet.length && (
            <RowCardsSection>
              <CardsSectionTitle>
                Escolha seu plano de Internet
              </CardsSectionTitle>
              <CardList
                list={plans.internet || []}
                buildCard={buildCardInternet}
              />
            </RowCardsSection>
          )}

          {/* {!!plans?.mobile.length && (
            <RowCardsSection>
              <CardsSectionTitle>
                Escolha seu plano de Celular
              </CardsSectionTitle>
              <CardList
                list={plans.mobile || []}
                buildCard={buildCardCelular}
              />
            </RowCardsSection>
          )} */}

          {selectedInternetPlan() && !!plans?.tv.length && (
            <RowCardsSection>
              <CardsSectionTitle>Escolha seu plano de TV</CardsSectionTitle>
              <CardList list={plans.tv || []} buildCard={buildCardsPlanTv} />
            </RowCardsSection>
          )}

          {selectedInternetPlan() && !!plans?.telefone.length && (
            <RowCardsSection>
              <CardsSectionTitle>
                Escolha seu plano de Telefone fixo
              </CardsSectionTitle>
              <CardList
                list={plans.telefone || []}
                buildCard={buildCardTelefonia}
              />
            </RowCardsSection>
          )}

          <Empty />
        </Container>
        <BoxDesktopSelecteds />
      </WrapperRow>

      <BoxMobileSelecteds />
    </MainLayout>
    </>
  );
}

const WrapperRow = styled.section`
  flex: 1.3;
  display: flex;
  align-items: stretch;
  height: 100%;
  position: relative;
`;

const LoadingWrapper = styled.div`
  flex: 1;
  width: 100%;
  flex: 1;
  margin-right: 0;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.section`
  flex: 2 2 43%;
  width: calc(100% - 36%);
  display: flex;
  flex-direction: column;
  margin-right: 15px;

  ${media.lessThan("medium")`
    width: 100%;
    flex: 1;
    margin-right: 0;
    max-width: 100%;
  `}

  & li {
    /* width: initial !important;
    max-width: 220px !important;
    flex: 1;
    min-width: 161px !important; */
  }
`;

const RowCardsSection = styled.section`
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const CardsSectionTitle = styled.h3`
  font-size: 1rem;
  margin-bottom:2rem;
  color: ${({ theme }) => theme.colors.secondary};
  ${media.lessThan("medium")`
    font-size: 0.9rem;
  `}
`;

const Empty = styled.div`
  height: 100px;
  align-self: stretch;
  display: block;
  ${media.greaterThan("medium")`
    display: none;
  `}
`;

const Wrapper = styled.div`
  flex: 1;
  align-items: center;
  flex-direction: column;
  display: flex;
`;

const Image = styled.img`
  width:70px;
  height:60px;
`;

const Title = styled.p`
  color: ${({ theme }) => theme.colors.secondary};
  font-family: "Nunito";
  font-size: 1.625rem;
  font-weight: normal;
  text-align: center;
  margin: 20px;
  margin-bottom: 1.3rem;
  line-height: 1.3;
`;

const Text = styled(Title)`
  text-align: center;
  font-size: 1.125rem;
  margin-top: 1.5rem;
  font-weight: bold;
  & strong {
    font-weight: bold;
  }
`;

export const ButtonAccept = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.secondary};
  flex: 1;
  margin: 20px;
  background-color: #ffffff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 11px;
  font-size: 18px;
  padding: 1rem;
  align-self: center;
  width: 226.16px;

  /* &:focus,
  &:hover {
    background-color: #d87e24;
  } */
`;


export default forwardRef(MountPackage);
