import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { ThemeProvider } from "styled-components";

import "rodal/lib/rodal.css";
import Loading from "components/loading/Loading.component";

import GlobalStyles from "theme/globalStyles";
import theme from "theme/theme";
import { StateProvider } from "state/ContextProvider";
import { LocationProvider } from "state/LocationProvider";

import AppRoutes from "AppRoutes";

function App() {
  return (
    <>
      <GlobalStyles />
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <StateProvider>
            <ToastProvider
              placement="top-right"
              autoDismiss
              autoDismissTimeout={4000}
            >
              <LocationProvider>
                <React.Suspense fallback={<Loading />}>
                  <AppRoutes />
                </React.Suspense>
              </LocationProvider>
            </ToastProvider>
          </StateProvider>
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
}

export default App;
