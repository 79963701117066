import React, { useEffect } from "react";
import styled from "styled-components";
import media from "styled-media-query";

import MainLayout from "layouts/Main.layout";
import { ReactComponent as Logo } from "assets/img/loga_logo.svg";

import {useGlobalState} from "state/ContextProvider";

function NotAllowed() {
  const {messageResponse, setMessageResponse} = useGlobalState();

  const onClickBackHome = () => {
    setMessageResponse("");
    window.location.href = "/";
  };

  return (
    <MainLayout title="Muito obrigado pelo contato">
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      <TitleStyle>Muito obrigado pelo contato</TitleStyle>

      <TextStyle>
        {messageResponse || "Ocorreu um erro ao finalizar pedido. Entre em contato com o suporte"}
      </TextStyle>


      <ButtonBackHome onClick={onClickBackHome}>
        Voltar para home
      </ButtonBackHome>
    </MainLayout>
  );
}

const TitleStyle = styled.h1`
  text-align: left;
  color: ${({ theme }) => theme.colors.compos};
  font-size: 2.3rem;
  margin-bottom: 2.5rem;
`;

const TextStyle = styled.p`
  font-family: "Nunito";
  color: ${({ theme }) => theme.colors.primary};
  line-height: 1.2;
  font-size: 1.6rem;
  max-width: 690px;
  margin-bottom: 2.5rem;
`;

const LogoWrapper = styled.div`
  width: 210px;
  height: 134px;
  margin-bottom: 2rem;
`;

const ButtonBackHome = styled.button`
  height: 50px;
  width: 100%;
  max-width: 289px;
  border-radius: 25px;
  background-color: ${({ theme }) => theme.colors.primary};
  transition: all ease 0.3s;
  &:focus,
  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.primary};
    background-color: rgba(238, 146, 54, 0.2);
  }
  color: ${({ theme }) => theme.colors.white};
  font-family: "VAGRounded BT";
  font-size: 1rem;

  ${media.lessThan("medium")`
    height: 50px;
    width: 100%;
    max-width: 100%;
  `}
`;

export default NotAllowed;
