// import axios from "axios";
import { requestMicroService } from './http'

// const REACT_APP_MS_URL_LEADS = process.env.REACT_APP_MS_URL_LEADS;
// const REACT_APP_MS_PORT_LEADS = process.env.REACT_APP_MS_PORT_LEADS;

// if (!REACT_APP_MS_URL_LEADS) {
//   throw new Error("Endereço da API LEADS não foi definida no .env");
// }

/**
 *
 * @param {{
    "nomeComprador": string, 
    "valor": number,
    "parcelas": number,
    "numeroCartao": string, 
    "nomeCartao": string,
    "dataExpiracao": string,
    "codigoSeguranca": string,
    "bandeira" : string
}} card
 * @param {string} uid
 */

export const saveMembershipCard = async (card, uid) => {
  const { data } = await requestMicroService('leads', 'post', `payments/${uid}`, card)

  return data;
};

