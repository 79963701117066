import React, { useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import FadeIn from "react-fade-in";

import MainLayout from "layouts/Main.layout";
import Accordion from "components/accordion/Accordion.component";
import AccordionBody from "components/accordion/AccodionBody.component";

import { useLocationState } from "state/LocationProvider";
import { getExpirationDays } from "services/billing";
import { useGlobalState } from "state/ContextProvider";

import Establishment from "./components/Establishment.component";
import BillingData from "./components/BillingData.component";
import OrderSummary from "./components/OrderSummary.component";
import MapAddress from "./components/MapAddress.component";

import OnlyMobileLocationForm from "./components/OnlyMobileLocationForm.component";
import Membership from "./components/MembershipPayment";

function Location() {
  const { name, combo, setCep, setExpirationDays } = useGlobalState();
  const { step, onlyMobile, choseOnlyMobile, setInProgress, hasMembershipPayment } =
    useLocationState();

  const navigation = useHistory();

  const findDays = useCallback(async () => {
    try {
      // setInProgress(true);
      const days = await getExpirationDays();
      if (!days.length) {
        throw new Error("Não recuperou datas de expiração");
      }
      setExpirationDays(days);
    } catch (err) {
      //
      setExpirationDays([]);
    } finally {
      // setInProgress(false);
    }
  }, []);

  useEffect(() => {
    window.dataLayer.push({
      event: "pageview",
      page: {
        url:
          document.location.origin +
          document.location.pathname +
          document.location.search,
        title: "Definir localização",
      },
    });
  }, []);

  useEffect(() => {
    if (!name.value || !combo.length) {
      setCep({
        value: "",
        error: "",
      });
      navigation.push("/");
    }

    findDays();
  }, [name.value, combo, findDays]);

  return (
    <MainLayout title="Definir localização" transparent>
      <FadeIn>
        <Accordion
          isExpanded={step === 1}
          title={
            onlyMobile.onlyMobile && choseOnlyMobile
              ? "Endereço de entrega"
              : "Local de instalação"
          }
          describe={
            onlyMobile.onlyMobile && choseOnlyMobile
              ? "Endereço que gostaria de receber o seu CHIP Loga."
              : undefined
          }
          number={4}
        >
          {step === 1 && (
            <AccordionBody>
              {onlyMobile.onlyMobile && choseOnlyMobile ? (
                <OnlyMobileLocationForm />
              ) : (
                <Establishment />
              )}
            </AccordionBody>
          )}
        </Accordion>

        <Accordion isExpanded={step === 2} title="Marque no mapa" number={5}>
          {step === 2 && (
            <AccordionBody>
              <MapAddress />
            </AccordionBody>
          )}
        </Accordion>

        <Accordion isExpanded={step === 3} title="Dados de cobrança" number={6}>
          {step === 3 && (
            <AccordionBody>
              <BillingData />
            </AccordionBody>
          )}
        </Accordion>

        {hasMembershipPayment(combo) && (
          <Accordion
            isExpanded={step === 4}
            title="Pagamento de Adesão"
            describe="Para continuar para agendamento você deve efetuar o pagamento da adesão"
            optionColor
            number={7}
          >
            {step === 4 && (
              <AccordionBody>
                <Membership />
              </AccordionBody>
            )}
          </Accordion>
        )}

        <Accordion
          // isExpanded={step === (hasMembershipPayment(combo) ? 5 : 4)}
          isExpanded={step === 5}
          title="Resumo do pedido"
          describe="Por favor, confira abaixo os dados do seu pedido"
          // number={8}
          number={hasMembershipPayment(combo) ? 8 : 7}
        >
          {/* {step === (hasMembershipPayment(combo) ? 5 : 4) && ( */}
          {step === 5 && (
            <AccordionBody>
              <OrderSummary />
            </AccordionBody>
          )}
        </Accordion>
      </FadeIn>
    </MainLayout>
  );
}

export default Location;
