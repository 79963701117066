import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

import VAGRoundedBold from "assets/fonts/vag-rounded-bt.ttf";

export default createGlobalStyle`
  ${reset}

  @font-face {
    font-family: "VAGRounded BT";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(${VAGRoundedBold});
  }

  body {
    font-family: 'VAGRounded BT';
    background-color: #EEF0F3;
    box-sizing: border-box;
    font-size: 1.6rem;
  }

  #root, html, body {
    min-height: 100%;
    min-width: 100%;
    overflow-x:  hidden;
  }

  * {
    box-sizing: border-box;
  }

  h1 {
    font-size: 10vw;
  }

  p {
    font-size: 5vw;
  }

  button {
    cursor: pointer;
    outline: none;
    border: none;
    background-color: transparent;
  }

  a {
    text-decoration: none;
  }

  input {
    border: none;
    padding: 1rem;
    &:focus {
      outline: none;
      border: 2px solid #EE9032;
      box-shadow: none;
    }
  }

  .slide-enter,
  .slide-exit {
    transition: transform 600ms ease-out;
  }

  .slide-enter {
    transform: translateX(100%);
  }

  .slide-enter.slide-enter-active {
    transform: translateX(0%);
  }

  .slide-exit {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transform: translateX(0%);
  }

  .slide-exit-active {
    transform: translateX(-100%);
  }
`;
