import React from "react";
import RSelect from "react-select";
import styled from "styled-components";
import media from "styled-media-query";

import SelectSearchButton from "components/select-search-button/SelectSearchButton.component";

const SelectSearch = ({
  name,
  label,
  id,
  defaultValue,
  options,
  value,
  getOptionLabel,
  getOptionValue,
  onSelectOption,
  onClickNewPredio,
  placeholder,
  disabled,
}) => {
  const handleChange = (option, action) => {
    if (action.action === "select-option") {
      onSelectOption(option);
    }
  };

  return (
    <FormGroupStyle>
      <LabelStyle htmlFor={id}>{label}</LabelStyle>

      <Group>
        <RSelect
          className="basic-single"
          classNamePrefix="select"
          defaultValue={defaultValue}
          isSearchable
          isDisabled={disabled}
          name={name}
          value={value}
          maxMenuHeight={100}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          onChange={handleChange}
          noOptionsMessage={() => "Os resultados aparecerão aqui..."}
          placeholder={placeholder}
          options={options}
          styles={selectStyle}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: "#EE9032",
              primary: "#EE9032",
            },
          })}
        />

        <SelectSearchButton
          text={disabled ? "Buscar prédio na lista" : "Não encontro meu prédio"}
          type={"line"}
          onClick={onClickNewPredio}
        />
      </Group>
    </FormGroupStyle>
  );
};

const selectStyle = {
  control: (styles) => {
    return {
      ...styles,
      height: "6vh",
      backgroundColor: "#EDEDED",
      width: "100%",
      borderRadius: 18,
      border: "none",
      padding: "0 0.6rem",
    };
  },
  singleValue: (provided) => {
    return {
      ...provided,
      fontSize: "1rem",
      fontFamily: "Nunito",
    };
  },
  option: (provided) => ({
    ...provided,
    fontSize: "1rem",
    fontFamily: "Nunito",
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#EDEDED",
    zIndex: "300",
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: "1rem",
  }),
  container: (provided) => ({
    ...provided,
    flex: 1,
    width: "100%",
    maxWidth: "auto",
  }),
};

const FormGroupStyle = styled.div`
  width: ${({ sm }) => String(sm)}%;
  margin: 0.6rem 0;
  position: relative;

  ${media.greaterThan("medium")`
    width: ${({ md }) => String(md)}%;

    &:ntc(:last-child) {
      margin-right: ${({ md }) => (md < 100 ? "13px" : "0")}
    }
  `}
`;

const LabelStyle = styled.label`
  font-size: 14px;
  font-family: "VAGRounded BT";
  color: ${({ theme }) => theme.colors.secondary};
  display: block;
  margin-bottom: 0.8rem;

  ${media.greaterThan("medium")`
    font-size: 18px;
  `}
`;

const Group = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;

  & > div {
    margin: 10px !important;
    margin-left: 0 !important;
  }

  ${media.lessThan("medium")`
    flex-direction: column;

    & > div {
      margin: 0 !important;
      margin-bottom: 10px !important;
    }
  `}
`;

export default SelectSearch;
