import React from "react";
import styled from "styled-components";
import media from "styled-media-query";

const Form = ({ children, ...rest }) => {
  return <FormStyle {...rest}>{children}</FormStyle>;
};

const FormStyle = styled.form`
  width: 100%;
  /* max-width: 1024px; */
  display: flex;
  flex-direction: column;
  margin: 1.4rem 0;
  position: relative;
  ${media.lessThan("medium")`
    margin: 1rem 0;
  `}
`;

export default Form;
