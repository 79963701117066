// import axios from "axios";
import { requestMicroService } from './http'

// const REACT_APP_MS_URL_CONNECT_MASTE =
//   process.env.REACT_APP_MS_URL_CONNECT_MASTER;

// const { REACT_APP_MS_PORT_CONNECTMASTER } = process.env;

// if (!REACT_APP_MS_URL_CONNECT_MASTE) {
//   throw new Error("Endereço da API LEADS não foi definida no .env");
// }

// export const getBuilding = async (cidade) => {
//   const { data } = await axios.post(
//     `${REACT_APP_MS_URL_CONNECT_MASTE.replace(
//       "{port}",
//       REACT_APP_MS_PORT_CONNECTMASTER
//     )}/predios`,
//     {
//       cidade,
//       nome_predio: "",
//     }
//   );
//   return data;
// };

export const getBuilding = async (cidade) => {
  const { data } = await requestMicroService('connectMaster', 'post', 'predios', {
    cidade,
    nome_predio: "",
  })
  
  return data;
};
