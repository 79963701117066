import React from 'react';
import styled, {keyframes} from 'styled-components';
import media from 'styled-media-query';

const ShadowButton = ({children, loading, disable,...rest}) => (
  <ButtonStyle {...rest} disabled={disable || loading}>
    {loading ? <Spinner /> : children}
  </ButtonStyle>
);

const ButtonStyle = styled.button`
  width: 100%;
  max-width: 474px;
  height: 6vh;
  min-height: 50px;
  margin: 0;
  border-radius: 12px;
  background-color: ${({theme}) => theme.colors.primary};
  opacity: ${({disabled}) => disabled ? '0.8' :'1'};
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  color: ${({theme}) => theme.colors.white};
  font-family: 'VAGRounded BT';
  font-size: 1rem;
  transition: all 0.3s ease;

  &:hover, &:focus {
    background-color: #D5771A;
  }

  ${media.lessThan('medium')`
    max-width: 100%;
  `}
`;

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  animation: ${rotate360} 0.8s linear infinite;
  transform: translateZ(0);

  border-top: 2px solid rgba(255, 255, 255, 0.4);
  border-right: 2px solid rgba(255, 255, 255, 0.4);
  border-bottom: 2px solid rgba(255, 255, 255, 0.4);
  border-left: 2px solid #eee;
  background: transparent;
  width: 24px;
  height: 24px;
  border-radius: 50%;
`;

export default ShadowButton;
