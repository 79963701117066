import React, { useEffect } from "react";
import styled from "styled-components";

import TextField from "components/text-field/TextField.component";
import { useLocationState } from "state/LocationProvider";
import { handleChangeText, handleChangeTextMasked } from "helpers/inputChanges";
import { useGlobalState } from "state/ContextProvider";

const FieldsResidencial = () => {
  const {
    numero,
    complemento,
    bairro,
    cidade,
    rua,

    setRua,
    setNumero,
    setBairro,
    setCep,
    setCidade,
    setComplemento
  } = useLocationState();

  const { leadAddress, cep } = useGlobalState();

  useEffect(() => {
    setBairro({ value: leadAddress.bairro, error: "" });
    setCidade({ value: leadAddress.cidade || leadAddress.localidade, error: "" });
    setRua({ value: leadAddress.endereco, error: "" });
  }, []);

  return (
    <>
      <RowStyle>
        <TextField
          onChange={(e) => handleChangeTextMasked(e, setCep, "99999-999")}
          name="cep"
          value={cep.value}
          label="CEP*"
          disabled
          required
          md={48}
        />

        <TextField
          onChange={(e) => handleChangeText(e, setCidade)}
          name="cidade"
          value={cidade.value}
          label="Cidade*"
          error={cidade.error}
          md={48}
          required
        />
      </RowStyle>

      <RowStyle>
        <TextField
          onChange={(e) => handleChangeText(e, setBairro)}
          name="bairro"
          value={bairro.value}
          label="Bairro*"
          error={bairro.error}
          required
        />

        <TextField
          onChange={(e) => handleChangeText(e, setRua)}
          name="rua"
          value={rua.value}
          label="Rua*"
          error={rua.error}
          required
        />

        <TextField
          onChange={(e) => handleChangeText(e, setNumero)}
          name="numero"
          value={numero.value}
          label="Numero*"
          error={numero.error}
          required
          md={48}
        />

        <TextField
          onChange={(e) => handleChangeText(e, setComplemento)}
          name="complemento"
          value={complemento.value}
          label="Complemento"
          error={complemento.error}
          required
          md={48}
        />
      </RowStyle>
    </>
  );
};

const RowStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;

export default FieldsResidencial;
