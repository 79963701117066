import React, { createContext, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import media from "styled-media-query";
import { FiAlertTriangle } from "react-icons/fi";

import { useToasts } from "react-toast-notifications";
import LogaModal from "components/loga-modal/LogaModal.component";
import theme from "theme/theme";
import { saveLeadChipDestinyAddress } from "services/leads";
import { useGlobalState } from "./ContextProvider";
import { savePlanos } from "services/mountCombo";

import alert from "assets/img/alert.svg";

const LocationContext = createContext();

const TextStyle = styled.p`
  font-family: "Nunito";
  color: ${({ theme }) => theme.colors.primary};
  line-height: 1.2;
  font-size: 1.6rem;
  max-width: 690px;
  margin-bottom: 2.5rem;
  padding: 0 20px;
`;

const Wrapper = styled.div`
  flex: 1;
  align-items: center;
  flex-direction: column;
  display: flex;
`;

const Title = styled.p`
  color: ${({ theme }) => theme.colors.secondary};
  font-family: "Nunito";
  font-size: 1.625rem;
  text-align: center;
  margin: 20px;
  line-height: 1.2;
`;

const Subtitle = styled(Title)`
  font-weight: bold;
  line-height: 1.3;

  & span {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const ButtonCancel = styled.button`
  /* height: 3.688rem; */
  flex: 1;
  margin: 20px;
  background-color: #ffffff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 11px;
  font-size: 22px;
  color: ${({ theme }) => theme.colors.secondary};
  padding: 1rem;
  align-self: stretch;

  &:focus,
  &:hover {
    background-color: #f1f1f1;
  }
`;

const Image = styled.img`
  width: 70px;
  height: 60px;
`;

const TitleModal = styled.p`
  color: ${({ theme }) => theme.colors.secondary};
  font-family: "Nunito";
  font-size: 1.625rem;
  font-weight: normal;
  text-align: center;
  margin: 20px;
  margin-bottom: 1.3rem;
  line-height: 1.3;
`;

const Text = styled(TitleModal)`
  text-align: center;
  font-size: 1.125rem;
  margin-top: 1.5rem;
  font-weight: bold;
  & strong {
    font-weight: bold;
  }
`;

export const ButtonAcceptModal = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.secondary};
  flex: 1;
  margin: 20px;
  background-color: #ffffff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 11px;
  font-size: 18px;
  padding: 1rem;
  align-self: center;
  width: 226.16px;

  /* &:focus,
  &:hover {
    background-color: #d87e24;
  } */
`;

export const Row2 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  ${media.lessThan("medium")`
    flex-direction: column;
  `};
`;

export const ButtonAccept = styled(ButtonCancel)`
  background-color: ${({ theme }) => theme.colors.primary};
  color: #fff;

  &:focus,
  &:hover {
    background-color: #d87e24;
  }
`;

export const LocationProvider = ({ children }) => {
  const {
    uid,
    cep: globalCep,
    leadAddress,
    ibge,
    combo,
    setCombo,
  } = useGlobalState();

  const [predialExists, setPredialExists] = useState(true);
  const [numero, setNumero] = useState({ value: "", error: "" });
  const [complemento, setComplemento] = useState({ value: "", error: "" });
  const [torre, setTorre] = useState({ value: "", error: "" });
  const [ala, setAla] = useState({ value: "", error: "" });
  const [nomePredio, setNomePredio] = useState({ value: "", error: "" });
  const [numeroAndares, setNumeroAndares] = useState({ value: "", error: "" });
  const [andar, setAndar] = useState({ value: "", error: "" });
  const [numeroApartamentos, setNumeroApartamentos] = useState({
    value: "",
    error: "",
  });
  const [toggleModal, setToggleModal] = useState(false);
  const [rua, setRua] = useState({ value: "", error: "" });
  const [bairro, setBairro] = useState({ value: "", error: "" });
  const [establishment, setEstablishment] = useState("");
  const [fullAddress, setFullAddress] = useState("");
  const [inProgress, setInProgress] = useState(false);
  const [portability, setPortability] = useState(false);
  const [portabilityMobile, setPortabilityMobile] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState({ value: "", error: "" });
  const [mobileNumber, setMobileNumber] = useState({ value: "", error: "" });
  const [choseOnlyMobile, setChoseOnlyMobile] = useState(false);
  const [mobileProvider, setMobileProvider] = useState({
    value: "",
    error: "",
  });
  const [mobileOperator, setMobileOperator] = useState({
    value: "",
    error: "",
  });
  const [phoneOperator, setPhoneOperator] = useState({
    value: "",
    error: "",
  });
  const [step, setStep] = useState(1);
  const [cep, setCep] = useState({ value: "", error: "" });
  const [dueDate, setDueDate] = useState({ value: "", error: "" });
  const [membershipPayment, setMembershipPayment] = useState({
    value: "",
    error: "",
  });
  const [newPredio, setNewPredio] = useState(false);
  const [cidade, setCidade] = useState({ value: "", error: "" });
  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const [billingInfo, setBillingInfo] = useState({
    cpf: "",
    dataNascimento: "",
    paymentMethod: null,
  });
  const [coords, setCoords] = useState({
    lat: -20.333197297142,
    lon: -40.28941480484618,
  });
  const [onlyMobile, setOnlyMobile] = useState({
    onlyMobile: false,
    haveMobile: false,
  });

  const navigation = useHistory();
  const [toggleModalErr, setToggleModalErr] = useState(false);
  const [errMessage, setErrMessage] = useState({
    title: "",
    message: "",
  });
  const [haveAcession, setHaveAcession] = useState(false);

  const hasMembershipPayment = items => false//items.some((item) => item?.adesao > 0)

  const onClickNextStep = () => {
    if (step === 5) {
      // salva dados
      return navigation.push("/protocolo");
    }

    if (step === 1 && establishment === "predial") {
      setStep(3);
      return;
    }

    if (step === 1 && onlyMobile.onlyMobile) {
      setStep(3);
      return;
    }

    if (step === 3) {
      return hasMembershipPayment(combo) ? setStep(4) : setStep(5);
    }

    setStep((oldState) => (oldState + 1 === 6 ? 5 : oldState + 1));
  };

  const onClickBack = () => {
    if (step === 1) {
      return navigation.goBack();
    }

    if (step === 3 && establishment === "predial") {
      setStep(1);
      return;
    }

    if (step === 3 && onlyMobile.onlyMobile) {
      setStep(1);
      return;
    }

    if (step === 4 && establishment === "negocio") {
      setStep(1);
      return;
    }

    setStep((oldState) => oldState - 1);
  };

  const getMobileFromCombo = () => {
    const product = combo.find((product) => product.grupos.includes("50"));

    return product;
  };

  const onClickContinue = async (e) => {
    try {
      setInProgress(true);
      setToggleModal((old) => !old);

      const mobileProduct = getMobileFromCombo();

      setCombo([mobileProduct]);
      setOnlyMobile({
        onlyMobile: true,
        haveMobile: true,
      });

      await savePlanos([mobileProduct.id], uid);

      await await saveLeadChipDestinyAddress(
        {
          cep: globalCep.value.replace(/\D/gi, ""),
          endereco: leadAddress.endereco,
          complemento: "",
          bairro: leadAddress.bairro,
          cidade: leadAddress.cidade || leadAddress.localidade,
          uf: "ES",
          ibge: ibge,
          ddd: "27",
        },
        uid
      );

      setInProgress(false);

      onClickNextStep(e);
    } catch (err) {
      setInProgress(false);
      setToggleModalErr(true);
      setErrMessage({
        ...errMessage,
        title: err?.response?.data?.message || "Opss... Falha ao salvar",
        message: "Falha ao salvar dados de entrega do CHIP",
      });
    }
  };

  const handleToggle = () => {
    setToggleModal((old) => !old);
  };

  const handleCancel = () => {
    setToggleModal((old) => !old);
    navigation.push("sem-suporte");
  };

  const handleToggleErr = () => setToggleModalErr((old) => !old);

  return (
    <LocationContext.Provider
      value={{
        numero,
        complemento,
        torre,
        ala,
        nomePredio,
        numeroAndares,
        numeroApartamentos,
        rua,
        bairro,
        establishment,
        coords,
        billingInfo,
        fullAddress,
        inProgress,
        step,
        cep,
        cidade,
        portability,
        phoneNumber,
        mobileOperator,
        dueDate,
        predialExists,
        newPredio,
        andar,
        membershipPayment,
        haveAcession,

        setNumero,
        setComplemento,
        setTorre,
        setAla,
        setNomePredio,
        setNumeroAndares,
        setNumeroApartamentos,
        setRua,
        setBairro,
        setEstablishment,
        setCoords,
        setBillingInfo,
        setFullAddress,
        setInProgress,
        setStep,
        onClickNextStep,
        onClickBack,
        setCep,
        setCidade,
        setPortability,
        setPhoneNumber,
        setMobileOperator,
        setDueDate,
        setPredialExists,
        setNewPredio,
        setAndar,
        selectedBuilding,
        setSelectedBuilding,
        onlyMobile,
        setOnlyMobile,
        mobileNumber,
        setMobileNumber,
        phoneOperator,
        setPhoneOperator,
        portabilityMobile,
        setPortabilityMobile,
        setToggleModal,
        mobileProvider,
        setMobileProvider,
        choseOnlyMobile,
        setChoseOnlyMobile,
        setMembershipPayment,
        setHaveAcession,
        hasMembershipPayment
      }}
    >
      {/* <Modal
        title="Muito obrigado pelo contato"
        open={toggleModal}
        onClose={() => setToggleModal((old) => !old)}
        footer={() => (
          <ButtonWrapper>
            <ShadowButton onClick={onClickContinue}>
              Continuar com Plano Móvel
            </ShadowButton>
          </ButtonWrapper>
        )}
      >
        <TextStyle>
          Ficamos muito felizes com seu interesse na nossa Internet.
          Infelizmente, nossa cobertura 100% Fibra Óptica ainda não atende seu
          endereço.
        </TextStyle>
        <TextStyle>
          Mas assim que a gente chegar por aí, nós vamos entrar em contato.
          Combinado?
        </TextStyle>
      </Modal> */}
      <LogaModal toggle={toggleModalErr} handleTogle={handleToggleErr}>
        <Wrapper>
          <Image src={alert} alt="Mensagem de alerta" />

          <TitleModal>{errMessage.title}</TitleModal>

          <Text>{errMessage.message}</Text>

          <ButtonAcceptModal onClick={handleToggleErr}>
            Fechar
          </ButtonAcceptModal>
        </Wrapper>
      </LogaModal>

      <LogaModal toggle={toggleModal} handleTogle={handleToggle}>
        <Wrapper>
          <FiAlertTriangle
            color={theme.colors.primary}
            size={70.36}
            style={{ alignSelf: "center" }}
          />
          <Title>
            Infelizmente não viabilidade para a internet nesse endereço
          </Title>

          <Subtitle>
            Mas você pode continuar com a compra do seu <br />
            <span>
              {getMobileFromCombo()?.nome || "Smart Loga 15GB"} normalmente
            </span>
          </Subtitle>

          <Row2>
            <ButtonCancel onClick={handleCancel}>Cancelar</ButtonCancel>
            <ButtonAccept onClick={onClickContinue}>
              Continuar com a compra
            </ButtonAccept>
          </Row2>
        </Wrapper>
      </LogaModal>
      {children}
    </LocationContext.Provider>
  );
};

export const useLocationState = () => {
  const context = useContext(LocationContext);

  if (!context) {
    throw new Error("Provider is not defined!");
  }

  const {
    numero,
    complemento,
    torre,
    ala,
    nomePredio,
    numeroAndares,
    numeroApartamentos,
    rua,
    bairro,
    establishment,
    coords,
    billingInfo,
    fullAddress,
    inProgress,
    step,
    cep,
    cidade,
    portability,
    phoneNumber,
    mobileOperator,
    dueDate,
    predialExists,
    newPredio,
    andar,
    membershipPayment,

    setNumero,
    setComplemento,
    setTorre,
    setAla,
    setNomePredio,
    setNumeroAndares,
    setNumeroApartamentos,
    setRua,
    setBairro,
    setEstablishment,
    setCoords,
    setBillingInfo,
    setFullAddress,
    setInProgress,
    setStep,
    onClickNextStep,
    onClickBack,
    setCep,
    setCidade,
    setPortability,
    setPhoneNumber,
    setMobileOperator,
    setDueDate,
    setPredialExists,
    setNewPredio,
    setAndar,
    selectedBuilding,
    setSelectedBuilding,
    onlyMobile,
    setOnlyMobile,
    mobileNumber,
    setMobileNumber,
    phoneOperator,
    setPhoneOperator,
    portabilityMobile,
    setPortabilityMobile,
    setToggleModal,
    mobileProvider,
    setMobileProvider,
    choseOnlyMobile,
    setChoseOnlyMobile,
    setMembershipPayment,
    hasMembershipPayment
  } = context;

  return {
    numero,
    complemento,
    torre,
    ala,
    nomePredio,
    numeroAndares,
    numeroApartamentos,
    rua,
    bairro,
    establishment,
    coords,
    billingInfo,
    fullAddress,
    inProgress,
    step,
    cep,
    cidade,
    portability,
    phoneNumber,
    mobileOperator,
    dueDate,
    predialExists,
    newPredio,
    andar,
    membershipPayment,

    setNumero,
    setComplemento,
    setTorre,
    setAla,
    setNomePredio,
    setNumeroAndares,
    setNumeroApartamentos,
    setRua,
    setBairro,
    setEstablishment,
    setCoords,
    setBillingInfo,
    setFullAddress,
    setInProgress,
    setStep,
    onClickNextStep,
    onClickBack,
    setCep,
    setCidade,
    setPortability,
    setPhoneNumber,
    setMobileOperator,
    setDueDate,
    setPredialExists,
    setNewPredio,
    setAndar,
    selectedBuilding,
    setSelectedBuilding,
    onlyMobile,
    setOnlyMobile,
    mobileNumber,
    setMobileNumber,
    phoneOperator,
    setPhoneOperator,
    portabilityMobile,
    setPortabilityMobile,
    setToggleModal,
    mobileProvider,
    setMobileProvider,
    choseOnlyMobile,
    setChoseOnlyMobile,
    setMembershipPayment,
    hasMembershipPayment
  };
};
