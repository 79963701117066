import React from "react";
import styled from "styled-components";
import media from "styled-media-query";

import ShadowOutlineButton from "components/shadow-outline-button/ShadowOutlineButton.component";
import ShadowButton from "components/shadow-button/ShadowButton.component";
import { useLocationState } from "state/LocationProvider";

const PrevNextBox = ({ overwriteNext, overwriteBack, disabled, text = "Próximo", type }) => {
  const { onClickBack, onClickNextStep, inProgress } = useLocationState();

  return (
    <Wrapper>
      <ShadowOutlineButton onClick={overwriteBack || onClickBack} type="button">
        Voltar
      </ShadowOutlineButton>

      <ShadowButton
        onClick={overwriteNext || onClickNextStep}
        type={type || "button"}
        loading={inProgress}
        disable={disabled || inProgress}
      >
        {text}
      </ShadowButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin: 0.8rem 0;

  ${media.lessThan("medium")`
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  `}
`;

export default PrevNextBox;
