// import axios from "axios";
import { requestMicroService } from './http'

// const REACT_APP_MS_URL_CEP = process.env.REACT_APP_MS_URL_CEP;
// const { REACT_APP_MS_PORT_CEP } = process.env;

// if (!REACT_APP_MS_URL_CEP) {
//   throw new Error("Endereço da API CEP não foi definida no .env");
// }

/**
 *
 *{
    "cep": string,
    "endereco": string,
    "complemento": "",
    "bairro": string,
    "localidade": string,
    "uf": "ES",
    "ibge": "3205200",
    "gia": "",
    "ddd": "27",
    "siafi": "5703"
}
 */

// export const getCEPAddress = async (cep) => {
//   const { data } = await axios.get(
//     `${REACT_APP_MS_URL_CEP.replace(
//       "{port}",
//       REACT_APP_MS_PORT_CEP
//     )}zipcode/search?zipcode=${cep.replace(/\D/gi, "")}`
//   );

//   return data;
// };

export const getCEPAddress = async (cep) => {
  const { data } = await requestMicroService("cep", "get", `search?zipcode=${cep.replace(/\D/gi, "")}`)

  return data;
};