import React from "react";
import styled from "styled-components";
import media from "styled-media-query";

import Bullet from "components/bullet/Bullet.component";

const Step = ({ title, number }) => (
  <Container>
    <Bullet color="secondary" number={number} />
    <Title>{title}</Title>
  </Container>
);

const Container = styled.section`
  margin-bottom: 30px;
  display: flex;
  align-items: center;
`;

const Title = styled.h2`
  margin-left: 15px;
  font-size: 1.3rem;
  color: ${({ theme }) => theme.colors.primary};
  ${media.lessThan("medium")`
    font-size: 1rem;
  `}
`;

export default Step;
