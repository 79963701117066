import React from "react";
import styled from "styled-components";
import media from "styled-media-query";

import { ReactComponent as ChevronIcon } from "assets/img/chevron_icon.svg";
import Bullet from "components/bullet/Bullet.component";

const Accordion = ({ children, isExpanded, title, number, describe, optionColor }) => {
  return (
    <AccordionWrapper className={isExpanded ? "open" : ""}>
      <Header haveDescribe={describe}>
        <StepWrapper haveDescribe={describe}>
          <Bullet color="secondary" number={number} />
          <Bloc>
            <Title>{title}</Title>
            {!!describe && 
            <Describe haveOptionColor={optionColor}>
              {describe}
            </Describe>
            }
          </Bloc>
        </StepWrapper>

        <IconWrapper className={isExpanded ? "open" : ""}>
          <ChevronIcon />
        </IconWrapper>
      </Header>
      {children}
    </AccordionWrapper>
  );
};

const AccordionWrapper = styled.div`
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: column;
  height: auto;
  margin: 1rem 0;
  border-radius: 12px;
  transition: height 2s ease;
  position: relative;
  width: 100%;
  padding: 15px 30px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.white};

  ${media.lessThan("medium")`
    padding: 15px 15px;
  `}

  &.open {
    height: auto;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  align-self: stretch;
  padding: 0;
  height: auto;

  justify-content: space-between;
  align-items: ${({ haveDescribe }) =>
    haveDescribe ? "flex-start" : "center"};
`;

const StepWrapper = styled.section`
  display: flex;
  align-items: ${({ haveDescribe }) =>
    haveDescribe ? "flex-start" : "center"};
`;

const Title = styled.h2`
  font-size: 1.3rem;
  color: ${({ theme }) => theme.colors.primary};
  font-family: "VAGRounded BT";
  text-align: left;

  ${media.lessThan("small")`
    font-size: 1rem;
  `}
`;

const IconWrapper = styled.div`
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transform: rotate(0deg);
  transition: transform 1s ease;

  & ${ChevronIcon} {
    color: ${({ theme }) => theme.colors.secondary};
    height: 25px;
  }

  &.open {
    transform: rotate(180deg);
  }
`;

const Bloc = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
`;

const Describe = styled.p`
  font-size: 0.8rem;
  margin-top: 0.9rem;
  color:${({haveOptionColor}) => 
    haveOptionColor ? "#00ADB8" : "#1A284A"};
`;

export default Accordion;
