import React from "react";

import * as Styles from "./logaModal.styles";
import "./logaModal.css";

const LogaModal = ({ toggle, handleTogle, children }) => {
  return (
    <Styles.Wrapper>
      <Styles.LogaModal visible={toggle} onClose={handleTogle}>
        {children}
      </Styles.LogaModal>
    </Styles.Wrapper>
  );
};

export default LogaModal;