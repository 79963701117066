import React from "react";
import styled from "styled-components";
import media from "styled-media-query";

/**
 *
 * @param $color primary | secondary
 */
const Bullet = ({ color = "seconday", number = 1 }) => (
  <BulletStyle color={color}>{number}</BulletStyle>
);

const BulletStyle = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  border-radius: calc(24px / 2);
  background-color: ${({ theme, color }) => theme.colors[color]};
  font-family: "VAGRounded BT";
  color: ${({ theme }) => theme.colors.white};
  justify-content: center;
  align-items: center;
  font-size: 16px;
`;

export default Bullet;
