import React, { createContext, useContext, useState } from "react";

const StateContext = createContext();

export const StateProvider = ({ children }) => {
  const [isBusiness, setIsBusiness] = useState(false);
  const [name, setName] = useState({ value: "", error: "" });
  const [email, setEmail] = useState({ value: "", error: "" });
  const [phone, setPhone] = useState({ value: "", error: "" });
  const [cep, setCep] = useState({ value: "", error: "" });
  const [expirationDays, setExpirationDays] = useState([]);
  const [document, setDocument] = useState({
    value: "",
    error: "",
  });
  const [dataNascimento, setDataNascimento] = useState({
    value: "",
    error: "",
  });
  const [city, setCity] = useState("");
  const [combo, setCombo] = useState([]);
  const [ibge, setIbge] = useState("");
  const [cpf, setCpf] = useState({ value: "", error: "" });
  const [leadAddress, setLeadAddres] = useState(null);
  const [chipDestiny, setChipDestiny] = useState({
    bairro: "",
    cep: "",
    cidade: "",
    complemento: "",
    numero: "",
    endereco: "",
  });
  const [uid, setUid] = useState("");
  const [protocolo, setProtocolo] = useState("");
  const [supported, setSupported] = useState(false);
  const [plots, setPlots] = useState([]);
  const [messageResponse,setMessageResponse] = useState("");

  return (
    <StateContext.Provider
      value={{
        name,
        email,
        phone,
        cep,
        city,
        combo,
        supported,
        ibge,
        cpf,
        uid,
        leadAddress,
        protocolo,
        isBusiness,
        messageResponse,

        setName,
        setEmail,
        setPhone,
        setCep,
        setCity,
        setCombo,
        setSupported,
        setIbge,
        setCpf,
        setUid,
        setLeadAddres,
        setProtocolo,
        setIsBusiness,
        chipDestiny,
        setChipDestiny,
        dataNascimento,
        setDataNascimento,
        document,
        setDocument,
        expirationDays,
        setExpirationDays,
        plots,
        setPlots,
        setMessageResponse,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useGlobalState = () => {
  const context = useContext(StateContext);

  if (!context) {
    throw new Error("Provider is not defined in global app!");
  }

  const {
    name,
    email,
    phone,
    cep,
    city,
    combo,
    supported,
    ibge,
    cpf,
    uid,
    leadAddress,
    protocolo,
    isBusiness,
    messageResponse,

    setName,
    setEmail,
    setPhone,
    setCep,
    setCity,
    setCombo,
    setSupported,
    setIbge,
    setCpf,
    setUid,
    setLeadAddres,
    setProtocolo,
    setIsBusiness,
    chipDestiny,
    setChipDestiny,
    dataNascimento,
    setDataNascimento,
    document,
    setDocument,
    expirationDays,
    setExpirationDays,
    plots,
    setPlots,
    setMessageResponse
  } = context;

  return {
    name,
    email,
    phone,
    cep,
    city,
    combo,
    supported,
    ibge,
    cpf,
    uid,
    leadAddress,
    protocolo,
    isBusiness,
    messageResponse,

    setName,
    setEmail,
    setPhone,
    setCep,
    setCity,
    setCombo,
    setSupported,
    setIbge,
    setCpf,
    setUid,
    setLeadAddres,
    setProtocolo,
    setIsBusiness,
    chipDestiny,
    setChipDestiny,
    dataNascimento,
    setDataNascimento,
    document,
    setDocument,
    expirationDays,
    setExpirationDays,
    plots,
    setPlots,
    setMessageResponse
  };
};
