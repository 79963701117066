import React, {useState} from "react";
import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useToasts } from "react-toast-notifications";
import * as yup from "yup";

import TextField from "components/text-field/TextField.component";
import PrevNexBox from "components/prev-next-box/PrevNextBox.component";
import { useGlobalState } from "state/ContextProvider";
import Form from "components/form/Form.component";
import { useLocationState } from "state/LocationProvider";
import { saveLeadChipDestinyAddress } from "services/leads";

import LogaModal from "components/loga-modal/LogaModal.component";
import alert from "assets/img/alert.svg";

const schema = yup.object().shape({
  cep: yup.string().trim().required("Digite um CEP."),
  cidade: yup.string().trim().required("Digite o nome da cidade."),
  bairro: yup.string().trim().required("Digite o nome do bairro."),
  endereco: yup.string().trim().required("Digite o nome da rua."),
  numero: yup.string().trim().required("Digite o número."),
  complemento: yup.string().trim().nullable(),
});

function OnlyMobileLocationForm() {
  const { chipDestiny, cep, setChipDestiny, uid, ibge } = useGlobalState();
  const { onClickNextStep, setInProgress } = useLocationState();

  const [toggleModalErr,setToggleModalErr] = useState(false);
  const [errMessage,setErrMessage] = useState({
    title:"",
    message:""
  });

  const { handleSubmit, errors, control } = useForm({
    resolver: yupResolver(schema),
  });

  /**
   * Form values
   * @param {{
   *  bairro: string;
   *  cep: string;
   *  cidade: string;
   *  complemento: string;
   *  numero: string;
   *  endereco: string;
   * }} data
   * @param {*} e
   */
  const onSubmit = async (data, e) => {
    setChipDestiny((old) => {
      return {
        ...old,
        ...data,
        cep: data.cep.replace(/\D/i, ""),
      };
    });

    try {
      setInProgress(true);
      // Integrar quando endpoint de lead estiver pronto

      await saveLeadChipDestinyAddress(
        { ...data, cep: data.cep.replace(/\D/i, ""), ddd: "27", ibge },
        uid
      );
    } catch (err) {
      setToggleModalErr(true);
       setErrMessage({
        ...errMessage,
        title:err.message,
        message:"Error"
      });
    } finally {
      setInProgress(false);
    }

    onClickNextStep(e);
  };

  const handleToggleErr = () => setToggleModalErr((old) => !old);

  return (
    <>
    <LogaModal toggle={toggleModalErr} handleTogle={handleToggleErr}>
        <Wrapper>
          <Image src={alert} alt="Mensagem de alerta"/>

          <TitleModal>
            {errMessage.title}
          </TitleModal>

          <Text>
            {errMessage.message}
          </Text>

          <ButtonAccept onClick={handleToggleErr}>Fechar</ButtonAccept>
        </Wrapper>
    </LogaModal>

    <Form onSubmit={handleSubmit(onSubmit)}>
      <RowStyle>
        <Controller
          name="cep"
          control={control}
          defaultValue={cep.value}
          render={(props) => (
            <TextField
              onChange={props.onChange}
              onBlur={props.onBlur}
              name="cep"
              value={props.value}
              label="CEP*"
              error={errors?.cep?.message}
              disabled
              md={28}
              mask="99.999-999"
            />
          )}
        />

        <Controller
          name="cidade"
          control={control}
          defaultValue={chipDestiny?.cidade || chipDestiny?.localidade}
          render={(props) => (
            <TextField
              onChange={props.onChange}
              onBlur={props.onBlur}
              disabled
              value={props.value}
              error={errors?.cidade?.message}
              name="cidade"
              label="Cidade*"
              md={68}
            />
          )}
        />
      </RowStyle>

      <RowStyle>
        <Controller
          name="bairro"
          control={control}
          defaultValue={chipDestiny?.bairro}
          render={(props) => (
            <TextField
              onChange={props.onChange}
              onBlur={props.onBlur}
              value={props.value}
              disabled
              error={errors?.bairro?.message}
              name="bairro"
              label="Bairro*"
            />
          )}
        />

        <Controller
          name="endereco"
          control={control}
          defaultValue={chipDestiny?.endereco}
          render={(props) => (
            <TextField
              onChange={props.onChange}
              onBlur={props.onBlur}
              disabled
              value={props.value}
              error={errors?.endereco?.message}
              name="endereco"
              label="Rua*"
            />
          )}
        />
      </RowStyle>

      <RowStyle>
        <Controller
          name="numero"
          control={control}
          defaultValue={chipDestiny?.numero}
          render={(props) => (
            <TextField
              onChange={props.onChange}
              onBlur={props.onBlur}
              value={props.value}
              error={errors?.numero?.message}
              name="numero"
              label="Número*"
              type="number"
              md={28}
            />
          )}
        />

        <Controller
          name="complemento"
          control={control}
          defaultValue=""
          render={(props) => (
            <TextField
              onChange={props.onChange}
              onBlur={props.onBlur}
              value={props.value}
              name="complemento"
              label="Complemento*"
              placeholder="Exe: Ed. Florida, Apt. 700"
              md={68}
            />
          )}
        />
      </RowStyle>

      <PrevNexBox overwriteNext={() => {}} type="submit" />
    </Form>
    </>
  );
}

const RowStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
`;

const Wrapper = styled.div`
  flex: 1;
  align-items: center;
  flex-direction: column;
  display: flex;
`;

const Image = styled.img`
  width:70px;
  height:60px;
`;

const TitleModal = styled.p`
  color: ${({ theme }) => theme.colors.secondary};
  font-family: "Nunito";
  font-size: 1.625rem;
  font-weight: normal;
  text-align: center;
  margin: 20px;
  margin-bottom: 1.3rem;
  line-height: 1.3;
`;

const Text = styled(TitleModal)`
  text-align: center;
  font-size:1.125rem;
  margin-top: 1.5rem;
  font-weight: bold;
  & strong {
    font-weight: bold;
  }
`;

export const ButtonAccept = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.secondary};
  flex: 1;
  margin: 20px;
  background-color: #ffffff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 11px;
  font-size: 18px;
  padding: 1rem;
  align-self: center;
  width: 226.16px;

  /* &:focus,
  &:hover {
    background-color: #d87e24;
  } */
`;

export default OnlyMobileLocationForm;
