import React from "react";
import styled from "styled-components";
import media from "styled-media-query";
// import { Link as RouterLink } from 'react-router-dom';

import { ReactComponent as Logo } from "assets/img/loga_logo.svg";
import { useScroll } from "hooks/useScroll";
// import Button from 'components/button/Button.component';

const Header = () => {
  const { scrollY } = useScroll();

  return (
    <Container minimize={scrollY > 80}>
      <NavContainer minimize={scrollY > 80}>
        <a href="/">
          <Logo />
        </a>

        {/* <Menu>
         <Stick />
         <Stick />
         <Stick />
        </Menu> */}

        {/* <NavBar>
          <NavList>
            <NavItem>
              <Link to="#">Quem somos</Link>
            </NavItem>

            <NavItem>
              <Link to="#">Loga fixo</Link>
            </NavItem>

            <NavItem>
              <Link to="#">Loga móvel</Link>
            </NavItem>

            <NavItem>
              <Link to="#">Loga Tv</Link>
            </NavItem>

            <NavItem>
              <Link to="#">Blog</Link>
            </NavItem>
          </NavList>

          <Buttons>
            <ButtonWrapper>
              <ButtonLabel>Eu quero loga</ButtonLabel>
              <Button text="Assine já" type="fill" />
            </ButtonWrapper>

            <ButtonWrapper>
              <ButtonLabel>Sou loga</ButtonLabel>
              <Button text="Minha loga" type="line" />
            </ButtonWrapper>
          </Buttons>

        </NavBar> */}
      </NavContainer>
    </Container>
  );
};

const Container = styled.header`
  align-self: stretch;
  padding: 1rem 1.3rem;
  background-color: ${({ theme }) => theme.colors.secondary};
  position: fixed;
  width: 100%;
  z-index: 1000;
  transition: all 0.6s ease-out;
`;

// const Menu = styled.button`
//   width: 29px;
//   position: relative;
//   background-color: transparent;
//   padding: 0;
//   height: 23px;
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;

//   ${media.greaterThan('medium')`
//     display: none;
//   `}
// `;

// const Stick = styled.span`
//   display: inline-block;
//   height: 3px;
//   width: 27px;
//   background-color: ${({theme}) => theme.colors.white};
// `;

const NavContainer = styled.nav`
  width: 100%;
  max-width: 100%;

  ${media.greaterThan("medium")`
    width: 85vw;
    max-width: 1224px;
  `}

  flex: 1;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & svg {
    width: 80.76px;
    height: 43.5px;

    ${media.greaterThan("medium")`
      width: 108.09px;
      height: ${({ minimize }) => (minimize ? "37px" : "58.22px")};
      transition: all 0.6s ease-out;
    `}
  }
`;

// const NavBar = styled.section`
//   flex: 1;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   margin-left: 1.7rem;

//   ${media.lessThan('medium')`
//     display: none;
//   `}
// `;

// const NavList = styled.ul`
//   display: flex;
//   margin-left: 20px;
//   list-style: none;
// `;

// const Link = styled(RouterLink)`
//   letter-spacing: 1.3px;
//   font-size: 16px;
//   color: rgba(255,255,255,0.81);

//   &:hover {
//     color: ${({theme}) => theme.colors.white};
//   }
// `;

// const NavItem = styled.li`
//   margin-right: 16px;
//   font-size: 14px;
//   line-height: 1em;
// `;

// const ButtonWrapper = styled.div`
//   display: flex;
//   width: auto;
//   flex-direction: column;
//   align-items: flex-start;
// `;

// const Buttons = styled.div`
//   display: flex;
//   & ${ButtonWrapper} {
//     margin-left: 16px;
//   }
// `;

// const ButtonLabel = styled.span`
//   text-align: left;
//   font-size: 1rem;
//   color: ${({theme}) => theme.colors.primary};
//   margin-bottom: 13px;
// `;

export default Header;
