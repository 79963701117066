// BillingData

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { cpf as cpfValidator, cnpj as cnpjValidator } from "cpf-cnpj-validator";
import { isValid, isAfter, parse, subYears } from "date-fns";

import { ReactComponent as ArrowIcon } from "assets/img/flex_arrow.svg";

import LogaModal from 'components/logaModal/logaModal';
import alert from 'assets/img/alert.svg';

import TextField from "components/text-field/TextField.component";
import Form from "components/form/Form.component";
import { isEmpty } from "helpers/validateFields";
import { handleChangeTextMasked, handleChangeText } from "helpers/inputChanges";
import { useLocationState } from "state/LocationProvider";
import PrevNextBox from "components/prev-next-box/PrevNextBox.component";
import SelectField from "components/select-field/SelectField.component";
import { useGlobalState } from "state/ContextProvider";
import { useToasts } from "react-toast-notifications";
import {
  saveIncremetalUserData,
  saveInvoiceDate,
  savePortability,
} from "services/billing";
import { formatToInternationalModel } from "helpers/formatTime";
import Modal from "components/modal/Modal.component";


const days = [
  { value: "07" },
  { value: "12" },
  { value: "15" },
  { value: "17" },
  { value: "25" },
  { value: "30" },
];

function BillingData() {
  const [isVisible, setIsVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [toggleModal,setToggleModal] = useState(false);
  const [toggleModalErr,setToggleModalErr] = useState(false);
  const [errMessage,setErrMessage] = useState({
    title:"",
    message:"",
    footer:""
  });
  const {
    setBillingInfo,
    onClickNextStep,
    portability,
    setPortability,
    phoneNumber,
    setPhoneNumber,
    mobileOperator,
    setMobileOperator,
    setDueDate,
    dueDate,
    setInProgress,
    onlyMobile,
    mobileNumber,
    setMobileNumber,
    phoneOperator,
    setPhoneOperator,
    portabilityMobile,
    setPortabilityMobile,
    mobileProvider,
    setMobileProvider,
    setStep,
  } = useLocationState();

  const { uid, name, email, phone, leadAddress, combo, isBusiness } =
    useGlobalState();

  const [document, setDocument] = useState({
    value: "",
    error: "",
  });
  const [dataNascimento, setDataNascimento] = useState({
    value: "",
    error: "",
  });
  const [paymentMethod] = useState("cartao");

  useEffect(() => {
    const condition = isBusiness
      ? document.value.length > 14
        ? document.value.length >= "99.999.999/999-99".length
        : document.value.length >= "999.999.999-99".length
      : document.value.length >= "999.999.999-99".length;

    if (condition) {
      const isValid = isBusiness
        ? document.value.length > 14
          ? cnpjValidator.isValid(document.value.replace(/\D/gi, ""))
          : cpfValidator.isValid(document.value.replace(/\D/gi, ""))
        : cpfValidator.isValid(document.value.replace(/\D/gi, ""));

      if (!isValid) {
        return setDocument((oldState) => ({
          ...oldState,
          error: isBusiness
            ? document.value.length > 14
              ? "Digite um cnpj válido"
              : "Digite um cpf válido."
            : "Digite um cpf válido.",
        }));
      }
    }
  }, [document.value]);

  // useEffect(() => {
  //   if (dataNascimento.value.length >= "99/99/9999".length) {
  //     const valideted = validateBirtDate(dataNascimento.value);

  //     if (!valideted) {
  //       setDataNascimento((oldState) => ({
  //         ...oldState,
  //         error: "Digite uma data de nascimento válida.",
  //       }));
  //     }
  //   }
  // }, [dataNascimento.value]);

  const handleClickNextButton = async (e) => {
    e.preventDefault();

    const result = isBusiness
      ? isEmpty([document, dueDate], [setDocument, setDueDate])
      : isEmpty(
          [document, dataNascimento, dueDate],
          [setDocument, setDataNascimento, setDueDate]
        );

    if (result) return;

    const documentIsValid = isBusiness
      ? document.value.length > 14
        ? cnpjValidator.isValid(document.value.replace(/\D/gi, ""))
        : cpfValidator.isValid(document.value.replace(/\D/gi, ""))
      : cpfValidator.isValid(document.value.replace(/\D/gi, ""));

    if (!documentIsValid) {
      return setDocument((oldState) => ({
        ...oldState,
        error: isBusiness
          ? document.value.length > 14
            ? "Digite um cnpj válido"
            : "Digite um cpf válido."
          : "Digite um cpf válido.",
      }));
    }

    // const dateIsValid = validateBirtDate(dataNascimento.value);

    // if (!dateIsValid && !isBusiness) {
    //   setDataNascimento((oldState) => ({
    //     ...oldState,
    //     error: "Digite uma data de nascimento válida.",
    //   }));
    // }

    if (portability) {
      const fieldsPortability = isEmpty(
        [phoneNumber, mobileOperator],
        [setPhoneNumber, setMobileOperator]
      );

      if (fieldsPortability) return;
    }

    setBillingInfo({
      cpf: document.value,
      nome: name.value,
      email: email.value,
      telefone: phone.value,
      endereco: leadAddress,
      dataNascimento: dataNascimento.value,
      isBusiness,
      paymentMethod,
    });

    setInProgress(true);

    await saveBilingData(e);
  };

  const saveBilingData = async (e) => {
    try {
      await savePortability(
        portability,
        portability ? phoneNumber.value : "",
        portability ? mobileOperator.value : "",
        uid
      );
      await saveInvoiceDate(dueDate.value, uid);

      const res = await saveIncremetalUserData(
        {
          cpf: document.value,
          data_nascimento: formatToInternationalModel(dataNascimento.value),
          nome: name.value,
          email: email.value,
          telefone: phone.value.replace(/\D/gi, ""),
          telefone2: phone.value.replace(/\D/gi, ""),
        },
        uid
      );

      
      setInProgress(false);
      onClickNextStep(e);
    } catch (err) {
      
      setInProgress(false);
      // setIsVisible(true);
      setToggleModalErr(true);
      setErrMessage({
        title:err?.response?.data?.message?.title,
        message:`
          01:${err?.response?.data?.message?.steps[0]},
          02:${err?.response?.data?.message?.steps[1]},
        `,
        footer:err?.response?.data?.message?.footer
      })
      
      // setErrorMessage(err?.response?.data?.message?.title);
      
    }
  };

  const handleToggleErr = () =>{
    setToggleModalErr(false);
  }

  const handleToggleWarn = () =>{
    setToggleModal(false);
  }

  // const closeModal = () => {
  //   setIsVisible(false);
  //   setErrorMessage("");
  // };

  const handleTogglePortability = (e) => {
    e.preventDefault();

    setPortability((oldState) => !oldState);
  };

  const handleToggleMobilrPortability = (e) => {
    e.preventDefault();

    setPortabilityMobile((oldState) => {
      if (!oldState) {
        setToggleModal(true);

        return !oldState;
      }
    });
  };

  const handleChangeDueDate = (e) => {
    const { value } = e.target;

    setDueDate({ value, error: "" });
  };

  const validateBirtDate = (value) => {
    const base = subYears(new Date(), 18);
    const date = parse(value, "dd/MM/yyyy", new Date());

    const dateValid = isValid(date);

    return dateValid && !isAfter(date, base);
  };

  return (
    <>
      <Form>
        {/* <Modal open={isVisible} title="" onClose={closeModal}>
          <ModalWrapper>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="73.523"
              height="63.954"
              viewBox="0 0 73.523 63.954"
            >
              <g
                id="Icon_feather-alert-triangle"
                data-name="Icon feather-alert-triangle"
                transform="translate(-0.747 -2.846)"
              >
                <path
                  id="Path_109"
                  data-name="Path 109"
                  d="M31.75,7.588,3.23,55.2A6.734,6.734,0,0,0,8.988,65.3H66.027a6.734,6.734,0,0,0,5.758-10.1L43.265,7.588a6.734,6.734,0,0,0-11.516,0Z"
                  transform="translate(0 0)"
                  fill="none"
                  stroke="#ee9032"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="3"
                />
                <path
                  id="Path_110"
                  data-name="Path 110"
                  d="M18,13.5V26.968"
                  transform="translate(19.508 11.394)"
                  fill="none"
                  stroke="#ee9032"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="3"
                />
                <path
                  id="Path_111"
                  data-name="Path 111"
                  d="M18,25.5h0"
                  transform="translate(19.508 26.331)"
                  fill="none"
                  stroke="#ee9032"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="3"
                />
              </g>
            </svg>
            <h1>{errorMessage}</h1>
          </ModalWrapper>
        </Modal> */}
        <LogaModal toggle={toggleModal} handleTogle={handleToggleWarn}>
        <Wrapper>
          <Title>Portabilidade do seu número</Title>

          <Subtitle>
            A portabilidade do seu número será solicitada no momento da ATIVAÇÃO
          </Subtitle>

          {/* <Portabilidade /> */}

          <Text>
            Assim que você receber seu chip <strong>SMART LOGA</strong>, você
            poderá ativa-lo pela nossa centra de atendimento no nosso 0800 ou
            pelo nosso aplicativo que pode ser baixado nas lojas de aplicativos.
            <br />
            <br />A partir do momento da ativação começará a valer o{" "}
            <strong>prazo máximo de 5 dias úteis</strong> para finalizar a
            portabilidade
          </Text>

          <ButtonAccept onClick={handleToggleWarn}>OK, entendi</ButtonAccept>
        </Wrapper>
      </LogaModal>
  { toggleModalErr? 
        <LogaModal toggle={toggleModalErr} handleTogle={handleToggleErr}>
            <Wrapper>
              <Image src={alert} alt="Mensagem de alerta"/>

              <Title>
                {errMessage.title}
              </Title>

              <Text>
                {errMessage.message}
              </Text>

              <Footer>
                {errMessage.footer}
              </Footer>

              <ButtonAccept onClick={handleToggleErr}>Fechar</ButtonAccept>
            </Wrapper>
        </LogaModal>
      : ''}
        <TextField
          name="document"
          label={
            isBusiness
              ? "CNPJ ou CPF caso seja um profissional liberal *"
              : "CPF*"
          }
          value={document.value}
          error={document.error}
          onChange={(e) =>
            handleChangeTextMasked(
              e,
              setDocument,
              isBusiness
                ? document.value.length >= 14
                  ? "99.999.999/9999-99"
                  : "999.999.999-99"
                : "999.999.999-99"
            )
          }
          required
        />

        {!isBusiness && (
          <TextField
            name="dataNascimento"
            label="Data de nascimento*"
            value={dataNascimento.value}
            error={dataNascimento.error}
            required
            onChange={(e) =>
              handleChangeTextMasked(e, setDataNascimento, "99/99/9999")
            }
          />
        )}

        <SelectField
          data={days}
          name="dia_fatura"
          id="dia_fatura"
          value={dueDate.value}
          onChange={handleChangeDueDate}
          value={dueDate.value}
          error={dueDate.error}
          label="Dia de vencimento da fatura"
          propertyLabel="value"
          required
          propertyValue="value"
        />

<PortabilityWrapper>
          {onlyMobile.haveMobile && (
            <>
              <PortabilityButton onClick={handleToggleMobilrPortability}>
                <span>
                  Deseja fazer a portabilidade do seu Telefone Celular?
                </span>
                <ArrowIcon />
              </PortabilityButton>

              {portabilityMobile && (
                <>
                  <RowStyle>
                    <TextField
                      name="mobileProvider"
                      label="Qual a sua operadora?*"
                      // placeholder="0 0000-0000"
                      value={mobileProvider.value}
                      require={portability}
                      error={mobileProvider.error}
                      onChange={(e) => handleChangeText(e, setMobileProvider)}
                      md={38}
                    />
                    <TextField
                      name="mobileNumber"
                      label="Qual seu numero do celular?*"
                      placeholder="(00) 00000-0000"
                      value={mobileNumber.value}
                      require={portability}
                      error={mobileNumber.error}
                      onChange={(e) =>
                        handleChangeTextMasked(
                          e,
                          setMobileNumber,
                          "(99) 99999-9999"
                        )
                      }
                      md={58}
                    />
                  </RowStyle>
                </>
              )}
            </>
          )}

          {combo.some((product) => product.telefonia) && (
            <>
              <PortabilityButton onClick={handleTogglePortability}>
                <span>Deseja fazer a portabilidade do seu Telefone Fixo?</span>
                <ArrowIcon />
              </PortabilityButton>

              {portability && (
                <>
                  <TextField
                    name="phoneNumber"
                    label="Qual seu telefone?*"
                    value={phoneNumber.value}
                    require={portability}
                    error={phoneNumber.error}
                    onChange={(e) =>
                      handleChangeTextMasked(
                        e,
                        setPhoneNumber,
                        "(99) 9999-9999"
                      )
                    }
                  />

                  <TextField
                    name="phoneNumber"
                    label="Operadora*"
                    value={phoneOperator.value}
                    require={portability}
                    error={phoneOperator.error}
                    onChange={(e) => handleChangeText(e, setPhoneOperator)}
                  />
                </>
              )}
            </>
          )}
        </PortabilityWrapper>
      </Form>

      <PrevNextBox overwriteNext={handleClickNextButton} />
    </>
  );
}

const PortabilityWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.2rem 0;
`;

const PortabilityButton = styled.button`
  width: 100%;
  height: 6vh;
  min-height: 50px;
  margin: 0;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colors.primary};
  opacity: ${({ disable }) => (disable ? "0.8" : "1")};
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  color: ${({ theme }) => theme.colors.white};
  font-family: "VAGRounded BT";
  font-size: 1rem;
  transition: all 0.3s ease;
  padding: 1rem;
  margin-bottom: 1.2rem;

  &:hover,
  &:focus {
    background-color: #d5771a;
  }

  & span {
    font-size: 0.8rem;
    text-align: center;
    color: ${({ theme }) => theme.colors.secondary};
    font-family: "Nunito";
    font-weight: 400;
  }
`;

const ModalWrapper = styled.div`
  width: 100%;
  max-width: 785px;
  display: flex;
  flex-direction: column;
  padding: 39px 79px;
  align-items: center;

  & h1 {
    font-size: 24px;
    font-family: "Nunito";
    font-weight: 400;
    color: ${({ theme }) => theme.colors.secondary};
    margin-bottom: 36px;
    margin-top: 40px;
    line-height: 1.1;
    text-align: center;
  }

  & h3 {
    font-size: 24px;
    font-family: "Nunito";
    font-weight: bold;
    color: ${({ theme }) => theme.colors.secondary};
    line-height: 1.1;
    text-align: center;
    margin-bottom: 5px;
  }

  & h3.primary {
    font-size: 24px;
    font-family: "Nunito";
    font-weight: bold;
    color: ${({ theme }) => theme.colors.primary};
    line-height: 1.1;
    text-align: center;
    margin-bottom: 0;
  }
`;
const Wrapper = styled.div`
  flex: 1;
  align-items: center;
  flex-direction: column;
  display: flex;

`;

const RowStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
`;

const Image = styled.img`
  width:70px;
  height:60px;
`;

const Title = styled.p`
  color: ${({ theme }) => theme.colors.secondary};
  font-family: "Nunito";
  font-size: 1.4rem;
  font-weight: normal;
  text-align: center;
  margin: 20px;
  margin-bottom: 1.3rem;
  line-height: 1.3;
`;

const Subtitle = styled(Title)`
  font-weight: bold;
  line-height: 1.3;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.secondary};
  margin-bottom: 2rem;
`;

const Text = styled(Title)`
  text-align: center;
  font-size: 1.1rem;
  margin-top: 1.5rem;
  font-weight: bold;
  & strong {
    font-weight: bold;
  }
`;

const Footer = styled(Title)`
  text-align: center;
  font-size: 0.8rem;
  margin-top: 1.5rem;
  font-weight: bold;
  & strong {
    font-weight: bold;
  }
`;

export const ButtonAccept = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.secondary};
  flex: 1;
  margin: 20px;
  background-color: #ffffff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 11px;
  font-size: 18px;
  padding: 1rem;
  align-self: center;
  width: 226.16px;

  /* &:focus,
  &:hover {
    background-color: #d87e24;
  } */
`;

export default BillingData;

