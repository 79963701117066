import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

const ShadowOutlineButton = ({children, ...rest}) => (
  <ButtonStyle {...rest}>
    {children}
  </ButtonStyle>
);

const ButtonStyle = styled.button`
  width: 100%;
  max-width: 474px;
  height: 6vh;
  min-height: 50px;
  margin: 0;
  border-radius: 12px;
  background-color: ${({theme}) => theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  color: ${({theme}) => theme.colors.sencondary};
  font-family: 'VAGRounded BT';
  font-size: 1rem;
  transition: 0.3s ease;
  border: 1px solid ${({theme}) => theme.colors.secondary};
  margin-right: 1.3rem;

  &:hover, &:focus {
    background-color: #F4F4F4;
  }

  ${media.lessThan('medium')`
    margin-right: 0;
    margin-bottom: 1rem;
    max-width: 100%;
  `}
`;

export default ShadowOutlineButton;
