import React from "react";
import styled from "styled-components";
import media from "styled-media-query";

import { ReactComponent as CheckMarkIcon } from "assets/img/checkmark_icon.svg";
import PopOver from "components/popover/PopOver.component";

const SelectEstablishment = ({
  title,
  value,
  selected,
  name,
  onChange,
  popover,
  id,
}) => {
  return (
    <LabelContainer active={selected && selected === value}>
      <Checkbox value={value} name={name} onChange={onChange} />

      <MarkerContainer active={selected && selected === value}>
        <CheckMarkIcon />
      </MarkerContainer>

      <TitleSpan>{title}</TitleSpan>
      {!!popover && <PopOver text={popover} id={id} />}
    </LabelContainer>
  );
};

const LabelContainer = styled.label`
  cursor: pointer;
  display: flex;
  height: 53px;
  width: 100%;
  background-color: ${({ active, theme }) =>
    active ? theme.colors.primary : theme.colors.white};
  border-radius: 15px;
  color: ${({ theme, active }) =>
    active ? theme.colors.white : theme.colors.secondary};
  font-family: "VAGRounded BT";
  margin-right: 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  margin: 1rem 0;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  position: relative;

  ${media.greaterThan("medium")`
    cursor: pointer;
    display: flex;
    height: auto;
    width: auto;
    background-color: transparent;
    border-radius: 0;
    color: ${({ theme }) => theme.colors.secondary};
    font-family: 'Nunito';
    box-shadow: none;
    margin: 0;
    font-size: 1.2rem;
    justify-content: flex-start;
    align-items: center;
    vertical-align: middle;

    &:not(:last-child) {
      margin-right: 1.3rem;
    }
  `}
`;

const TitleSpan = styled.span`
  line-height: 1.2;
  text-align: center;
  ${media.lessThan("medium")`
    text-align: center;
  `}
`;

const Checkbox = styled.input.attrs({
  type: "radio",
})`
  height: 0;
  width: 0;
  position: absolute;
  transform: translateX(-999999px);
`;

const MarkerContainer = styled.span`
  display: none;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: ${({ active }) => (active ? "none" : "1px solid #707070")};
  margin-right: 0.7rem;
  background-color: ${({ active }) => (active ? "#00ADB8" : "transparent")};

  ${media.greaterThan("medium")`
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`;

export default SelectEstablishment;
