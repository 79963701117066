import React, { useEffect, useCallback, useState } from "react";
import styled from "styled-components";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";

import { getLocationFromLatLng } from "services/geocoding";
import { useLocationState } from "state/LocationProvider";
import Map from "components/map/Map.component";
import PrevNexBox from "components/prev-next-box/PrevNextBox.component";
import { saveResidencialLocation } from "services/location";
import { useGlobalState } from "state/ContextProvider";
import { getCEPAddress } from "services/address";
import { getPlansByIbge } from "services/plans";
import Modal from "components/modal/Modal.component";
import LogaModal from "components/loga-modal/LogaModal.component";
import ShadowButton from "components/shadow-button/ShadowButton.component";
import alert from "assets/img/alert.svg";


function MapAddress() {
  const [next, setNext] = useState();
  

  const {
    setCoords,
    coords,
    fullAddress,
    setFullAddress,
    setInProgress,
    establishment,
    setNumero,
    onlyMobile,
    setToggleModal,
    onClickNextStep
  } = useLocationState();

  const {
    uid,
    cep,
    setLeadAddres,
    leadAddress,
    combo,
    supported,
    setSupported,
    isBusiness,
    ibge,
  } = useGlobalState();

  const navigation = useHistory();
  const [toggleModalErr,setToggleModalErr] = useState(false);
  const [errMessage,setErrMessage] = useState({
    title:"",
    message:""
  });

  const fetchLocal = useCallback(async () => {
    try {
      setInProgress(true);

      const results = await getLocationFromLatLng(coords.lat, coords.lon);

      if (!results.length) throw new Error();
      const { address_components, formatted_address } = results[0];

      const postalCode = address_components.find(
        (part) => part.types[0] === "postal_code"
      );
      const residencialNumber = address_components.find(
        (part) => part.types[0] === "street_number"
      );

      if (!postalCode || postalCode.long_name.length < 7) {
        throw new Error("1");
      }

      setFullAddress(formatted_address);

      if (!postalCode) {
        throw new Error("CEP não encontrado.");
      }

      // setCep({
      //   value: postalCode.long_name,
      //   error: "",
      // });

      setNumero({
        value: residencialNumber ? residencialNumber.long_name : "S/N",
        error: "",
      });

      let zipNumber = cep.value.replace("-","");

      await getCEPAddress(postalCode.long_name);
      await getPlansByIbge(ibge, isBusiness ? 0 : 1, zipNumber);
      setLeadAddres({
        ...leadAddress,
        cep: cep.value,
      });
      setSupported(true);
      setNext(true);
    } catch (err) {
      setNext(false);
      if (err.message === "1") {
        setToggleModalErr(true);
        setErrMessage({
          ...errMessage,
          title:"CEP inválido",
          message:"O local escolhido não possui um CEP válido."
        });
      }

      if (
        err?.response?.data?.message === "Esta cidade não possui planos ativos."
      ) {
        setNext(true);
        setInProgress(false);
        setSupported(false);
        return;
      }

      if (err.message !== "1") {
        setToggleModalErr(true);
        setErrMessage({
          ...errMessage,
          title:err?.response?.data?.message || "Erro CEP",
          message:"Falha ao obter local."
        });
      }
    } finally {
      setInProgress(false);
    }
  }, [coords]);

  useEffect(() => {
    if (!combo.length || !cep.value) {
      return navigation.push("/");
    }

    if (establishment === "residencial") {
      fetchLocal();
    }
  }, [fetchLocal]);

  const onClick = async (...params) => {
    const [, , geocode] = params;
    const { latLng } = geocode;

    const lat = latLng.lat();
    const lng = latLng.lng();

    setCoords({
      lat: lat,
      lon: lng,
    });
  };

  const onClickNext = async (e) => {
    let success = false;
    try {
      if (!supported) {
        return navigation.push("sem-suporte");
      }
      if (!next) {
        setToggleModalErr(true);
        return setErrMessage({
          ...errMessage,
          title:"Local inválido",
          message:"O local escolhido é inválido."
        });

      }
      setInProgress(true);
      const res = await saveResidencialLocation(
        {
          latitudeCliente: String(coords.lat),
          longitudeCliente: String(coords.lon),
          cep: cep.value.replace(/\D/gi, ""),
          ...leadAddress,
        },
        uid
      );

      if (
        res.value.data &&
        res.value.data.viabilidade_status !== 1 &&
        !onlyMobile.haveMobile
      ) {
        setSupported(false);
        return navigation.push("/sem-suporte");
      }

      success = true;
    } catch (err) {
      success = false;
      setToggleModalErr(true);
       setErrMessage({
        ...errMessage,
        title:"Opss... Falha ao salvar",
        message:"Falha ao salvar informações."
      });
      
    } finally {
      setInProgress(false);

      success && next && onClickNextStep(e);
    }
  };

  const handleToggleErr = () => setToggleModalErr((old) => !old);

  return (
    <>
      <LogaModal toggle={toggleModalErr} handleTogle={handleToggleErr}>
        <Wrapper>
          <Image src={alert} alt="Mensagem de alerta"/>

          <Title>
            {errMessage.title}
          </Title>

          <Text>
            {errMessage.message}
          </Text>

          <ButtonAccept onClick={handleToggleErr}>Fechar</ButtonAccept>
        </Wrapper>
      </LogaModal>

      <InputWrapper>
        <OutLineInput value={fullAddress} disabled />
      </InputWrapper>

      <Map onClick={onClick} />
      <PrevNexBox overwriteNext={onClickNext} disabled={!next} />
    </>
  );
}

const InputWrapper = styled.div`
  position: relative;
`;

const OutLineInput = styled.input`
  width: 100%;
  height: 52px;
  border: 2px solid ${({ theme }) => theme.colors.primary};
  border-radius: 10px;
  margin-bottom: 1rem;
  font-family: "Nunito";
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 1rem;
`;

const Wrapper = styled.div`
  flex: 1;
  align-items: center;
  flex-direction: column;
  display: flex;
`;

const Image = styled.img`
  width:70px;
  height:60px;
`;

const Title = styled.p`
  color: ${({ theme }) => theme.colors.secondary};
  font-family: "Nunito";
  font-size: 1.625rem;
  font-weight: normal;
  text-align: center;
  margin: 20px;
  margin-bottom: 1.3rem;
  line-height: 1.3;
`;

const Text = styled(Title)`
  text-align: center;
  font-size:1.125rem;
  margin-top: 1.5rem;
  font-weight: bold;
  & strong {
    font-weight: bold;
  }
`;

export const ButtonAccept = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.secondary};
  flex: 1;
  font-size: 18px;
  margin: 20px;
  background-color: #ffffff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 11px;
  padding: 1rem;
  align-self: center;
  width: 226.16px;

  /* &:focus,
  &:hover {
    background-color: #d87e24;
  } */
`;


export default MapAddress;
