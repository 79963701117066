import React from "react";
import styled from "styled-components";
import media from "styled-media-query";

const MainContent = ({ children, transparent }) => (
  <Container transparent={transparent}>{children}</Container>
);

const Container = styled.main`
  flex: 1;
  display: flex;
  padding: 1.5rem;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  margin-top: 4.3rem;
  margin-bottom: 0;
  background-color: ${({ theme, transparent }) =>
    transparent ? "transparent" : theme.colors.white};

  ${media.greaterThan("medium")`
    margin: 0 auto;
    margin-top: 7.2rem;
    margin-bottom: 2rem;
    width: 85vw;
    max-width: 70vw;
    padding: 3rem 2.4rem;
  `}
`;

export default MainContent;
