import React from 'react'
import {Map as GoogleMap, Marker, GoogleApiWrapper} from 'google-maps-react'
import styled from 'styled-components';
import media from 'styled-media-query';
import { useLocationState } from 'state/LocationProvider';

const Map = ({onClick, google}) => {
  const { coords } = useLocationState()

  return (
    <MapWrapper>
      <GoogleMap
        zoom={16}
        onClick={onClick}
        google={google}
        streetViewControl={false}
        style={{ borderRadius: "1rem", height: '100%'}}
        center={{
          lat: coords.lat,
          lng: coords.lon
        }}
        initialCenter={{
          lat: coords.lat,
          lng: coords.lon
        }}
        containerStyle={{
          position: 'relative',
          width: '100%',
          height: '100%'
        }}
      >
        <Marker position={{
          lat: coords.lat,
          lng: coords.lon
        }} />
      </GoogleMap>
    </MapWrapper>
  )
}

const MapWrapper = styled.section`
  height: 54.13vh;
  min-height: 380px;
  width: 100%;
  margin-top: 0.8rem;
  border-radius: 30px;

  ${media.greaterThan('medium')`
    height: 60vh;
  `}
`;

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_MAP_KEY,
  language: 'pt-BR'
})(Map)
