import React, {useState} from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import media from "styled-media-query";
import { useToasts } from "react-toast-notifications";
import FadeIn from "react-fade-in";

import { ReactComponent as InternetIcon } from "assets/img/net_icon.svg";
import { ReactComponent as CellPhoneIcon } from "assets/img/phone_icon.svg";
import { ReactComponent as PhoneIcon } from "assets/img/fixo_icon.svg";
import { ReactComponent as TvIcon } from "assets/img/tv_icon.svg";

import { formatBRL } from "helpers/formatCurrency";
import { useGlobalState } from "state/ContextProvider";
import { useLocationState } from "state/LocationProvider";
import { Spinner } from "views/location/components/Establishment.component";
import { savePlanos } from "services/mountCombo";

import LogaModal from "components/loga-modal/LogaModal.component";
import alert from "assets/img/alert.svg";

const icons = {
  internet: () => <InternetIcon />,
  mobile: () => <CellPhoneIcon />,
  televisao: () => <TvIcon />,
  telefone: () => <PhoneIcon />,
};

const BoxMobileSelecteds = () => {
  const { combo, uid } = useGlobalState();

  const { inProgress, setInProgress } = useLocationState();

  const navigation = useHistory();
  const [toggleModalErr,setToggleModalErr] = useState(false);
  const [errMessage,setErrMessage] = useState({
    title:"",
    message:""
  });

  const getTotal = (items) =>
    items.reduce((prev, curr) => prev + curr.preco, 0);

  const getPlanType = (plan) => {
    if (plan.is_tv) return "televisao";

    if (plan.telefonia) return "telefone";

    if (plan.velocidade) return "internet";

    if (plan?.grupos == "50") {
      return "mobile";
    }
  };

  const onClickNext = async () => {
    let success = false;
    try {
      if (combo.length) {
        setInProgress(true);
        const ids = combo.map((plan) => plan.id);
        await savePlanos(ids, uid);
        success = true;
      }
    } catch (err) {
      setToggleModalErr(true);
       setErrMessage({
        ...errMessage,
        title:"Opss... Falha ao salvar",
        message:"Falha ao salvar os dados"
      });
      success = false;
    } finally {
      setInProgress(false);

      success && navigation.push("/localizacao");
    }
  };

  const getAccessionItems = (items) => {
    const filtered = items.filter((item) => item?.adesao > 0);

    if (!filtered.length) return null;

    return filtered.map((item) => (
      <FadeIn key={`access2-${item.id}`}>
        <span>
          Taxa de adesão do {item.nome} de{" "}
          <strong>{formatBRL(item.adesao)}</strong> na primeira fatura.
        </span>
      </FadeIn>
    ));
  };

  const handleToggleErr = () => setToggleModalErr((old) => !old);

  return (
    <>
    <LogaModal toggle={toggleModalErr} handleTogle={handleToggleErr}>
        <Wrapper>
          <Image src={alert} alt="Mensagem de alerta"/>

          <TitleModal>
            {errMessage.title}
          </TitleModal>

          <Text>
            {errMessage.message}
          </Text>

          <ButtonAccept onClick={handleToggleErr}>Fechar</ButtonAccept>
        </Wrapper>
    </LogaModal>
    <Container>
      <Total>
        <Title>Produtos selecionados</Title>

        <Value>{formatBRL(getTotal(combo))}</Value>
      </Total>

      {combo.some((item) => item?.adesao > 0) && (
        <Acception>{getAccessionItems(combo)}</Acception>
      )}

      <SelectedsWrapper>
        <IconsWrapper>
          {combo.map((plan, index) => (
            <FadeIn key={`item-icon-${plan.id}`}>
              <Icon>
                {/* <h1 id="findme"></h1> */}
                {icons[getPlanType(plan) || 'televisao']()}
                {!!combo.length && !!combo[index + 1] && <Plus>+</Plus>}
              </Icon>
            </FadeIn>
          ))}
        </IconsWrapper>

        {!!combo.length && (
          <NextButton onClick={onClickNext} disabled={inProgress}>
            {inProgress ? (
              <Spinner />
            ) : (
              <NextButtonText>Próximo</NextButtonText>
            )}
          </NextButton>
        )}

        {!combo.length && (
          <Wr>
            <TextSelect>Escolha um produto</TextSelect>
          </Wr>
        )}
      </SelectedsWrapper>
    </Container>
    </>
  );
};

const Container = styled.section`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 400;
  display: block;

  ${media.greaterThan("medium")`
    display: none;
  `}
`;

const Wr = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const Total = styled.section`
  width: 100%;
  padding: 0.8rem 1rem;
  background-color: ${({ theme }) => theme.colors.compos};
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

const Acception = styled.div`
  width: 100%;
  padding: 0.8rem 1rem;
  background-color: ${({ theme }) => theme.colors.secondary};
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  & span {
    color: ${({ theme }) => theme.colors.white};
    font-size: 0.75rem;
    font-family: Nunito;
    line-height: 0.9rem;
    display: inline-block;
    &:not(:last-child) {
      margin-bottom: 5px;
    }
    & strong {
      font-weight: bold;
    }
  }
`;

const Title = styled.span`
  font-family: Nunito;
  font-size: 0.9rem;
  color: ${({ theme }) => theme.colors.white};
`;

const Value = styled.p`
  font-family: "VAGRounded BT";
  color: ${({ theme }) => theme.colors.white};
  font-size: 1rem;
`;

const SelectedsWrapper = styled.section`
  height: 72px;
  width: 100%;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
`;

const IconsWrapper = styled.section`
  display: flex;
  align-items: center;
`;

const Icon = styled.span`
  display: flex;
  align-items: center;
`;

const Plus = styled.span`
  font-size: 1rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.secondary};
  font-family: "VAGRounded BT";
  margin: 0 10px;
`;

const NextButton = styled.button`
  width: 27vw;
  height: 2.5rem;
  background-color: ${({ theme }) => theme.colors.secondary};
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NextButtonText = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.8rem;
  font-family: "Nunito";
  font-weight: 700;
`;

const TextSelect = styled(NextButtonText)`
  color: ${({ theme }) => theme.colors.secondary};
`;

const Wrapper = styled.div`
  flex: 1;
  align-items: center;
  flex-direction: column;
  display: flex;
`;

const Image = styled.img`
  width:70px;
  height:60px;
`;

const TitleModal = styled.p`
  color: ${({ theme }) => theme.colors.secondary};
  font-family: "Nunito";
  font-size: 1.625rem;
  font-weight: normal;
  text-align: center;
  margin: 20px;
  margin-bottom: 1.3rem;
  line-height: 1.3;
`;

const Text = styled(TitleModal)`
  text-align: center;
  font-size:1.125rem;
  margin-top: 1.5rem;
  font-weight: bold;
  & strong {
    font-weight: bold;
  }
`;

export const ButtonAccept = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.secondary};
  flex: 1;
  margin: 20px;
  background-color: #ffffff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 11px;
  font-size: 18px;
  padding: 1rem;
  align-self: center;
  width: 226.16px;

  /* &:focus,
  &:hover {
    background-color: #d87e24;
  } */
`;

export default BoxMobileSelecteds;
