import React from "react";
import styled from "styled-components";
import media from "styled-media-query";
import InputMask from "react-input-mask";

const TextField = ({
  label,
  name,
  id,
  value,
  onChange,
  onBlur,
  type,
  error,
  required,
  disabled,
  placeholder,
  mask,
  helper = "",
  md = 100,
  sm = 100,
  refs,
}) => {
  const onKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!/\d+/.test(keyValue)) event.preventDefault();
  };

  const Field = mask ? TextFieldMaskStyle : FormInputStyle;

  return (
    <FormGroupStyle md={md} sm={sm}>
      <LabelStyle htmlFor={id}>{label}</LabelStyle>

      <Field
        id={id}
        ref={refs}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        type={type === "number" ? undefined : type}
        required={required}
        onKeyPress={type === "number" ? onKeyPress : undefined}
        disabled={disabled}
        mask={mask}
        placeholder={placeholder}
      />

      {error ? (
        <ErrorStyle>{error}</ErrorStyle>
      ) : (
        <HelpStyle>{helper}</HelpStyle>
      )}
    </FormGroupStyle>
  );
};

const FormGroupStyle = styled.div`
  width: ${({ sm }) => String(sm)}%;
  margin: 1rem 0;
  position: relative;

  ${media.greaterThan("medium")`
    width: ${({ md }) => String(md)}%;

    &:ntc(:last-child) {
      margin-right: ${({ md }) => (md < 100 ? "13px" : "0")}
    }
  `}
`;

const LabelStyle = styled.label`
  font-size: 14px;
  font-family: "VAGRounded BT";
  color: ${({ theme }) => theme.colors.secondary};
  display: block;
  margin-bottom: 0.8rem;

  ${media.greaterThan("medium")`
    font-size: 18px;
  `}
`;

const FormInputStyle = styled.input`
  height: 6vh;
  max-height: 50px;
  min-height: 40px;
  width: 100%;
  background-color: ${({ theme, disabled }) =>
    disabled ? "#F8F8F8" : theme.colors.input};
  border-radius: 18px;
  font-family: "Nunito";
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 1rem;

  &:focus {
    border: 2px solid ${({ theme }) => theme.colors.primary};
  }

  ${media.lessThan("medium")`
    width: 100%;
  `}
`;

const TextFieldMaskStyle = styled(InputMask)`
  height: 6vh;
  max-height: 50px;
  min-height: 40px;
  width: 100%;
  background-color: ${({ theme, disabled }) =>
    disabled ? "#F8F8F8" : theme.colors.input};
  border-radius: 18px;
  font-family: "Nunito";
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 1rem;

  &:focus {
    border: 2px solid ${({ theme }) => theme.colors.primary};
  }

  ${media.lessThan("medium")`
    width: 100%;
  `}
`;

const ErrorStyle = styled.small`
  color: ${({ theme }) => theme.colors.red};
  font-family: "Nunito";
  font-size: 0.9rem;
  letter-spacing: 0.5px;
  margin: 0.6rem 0;
  display: block;
`;

const HelpStyle = styled(ErrorStyle)`
  color: ${({ theme }) => theme.colors.primary};
`;

export default TextField;
