import React from "react";

import TextField from "components/text-field/TextField.component";
import { useLocationState } from "state/LocationProvider";
import { handleChangeText, handleOnBlur } from "helpers/inputChanges";
import styled from "styled-components";
import { useGlobalState } from "state/ContextProvider";

const FieldsPredial = () => {
  const {
    numero,
    torre,
    ala,
    numeroAndares,
    newPredio,
    nomePredio,
    andar,
    rua,
    numeroApartamentos,

    setNumero,
    setTorre,
    setAla,
    setNumeroAndares,
    setAndar,
    setRua,
    setNomePredio,
    setNumeroApartamentos,
  } = useLocationState();

  const { leadAddress } = useGlobalState(0);

  React.useEffect(() => {
    if (!newPredio) {
      clear([setNumeroAndares, setNumeroApartamentos]);
    }
  }, [newPredio]);

  const clear = (sets) => {
    sets.forEach((set) => {
      set({
        value: "",
        error: "",
      });
    });
  };

  if (newPredio) {
    return (
      <>
        <TextField
          onChange={(e) => handleChangeText(e, setNomePredio)}
          name="nomePredio"
          value={nomePredio.value}
          label="Qual é o nome do seu prédio?*"
          error={newPredio && setNomePredio.error}
          onBlur={(e) => handleOnBlur(e, setNomePredio, null, newPredio)}
          required={newPredio}
        />

        <RowStyle>
          <TextField
            onChange={(e) => handleChangeText(e, setNumeroAndares)}
            name="numeroAndares"
            value={numeroAndares.value}
            label="Nº de andares*"
            error={newPredio && numeroAndares.error}
            onBlur={(e) => handleOnBlur(e, setNumeroAndares, null, newPredio)}
            required={newPredio}
            md={48}
          />

          <TextField
            onChange={(e) => handleChangeText(e, setNumeroApartamentos)}
            name="numeroApartamentos"
            value={numeroApartamentos.value}
            label="Nº de apartamentos"
            error={newPredio && numeroApartamentos.error}
            onBlur={(e) => handleOnBlur(e, setNumeroApartamentos)}
            md={48}
          />
        </RowStyle>

        <RowStyle>
          <TextField
            onChange={(e) => handleChangeText(e, setTorre)}
            name="torre"
            value={torre.value}
            label="Torre"
            md={48}
          />

          <TextField
            onChange={(e) => handleChangeText(e, setAla)}
            name="ala"
            value={ala.value}
            label="Ala"
            md={48}
          />
        </RowStyle>

        <TextField
          onChange={(e) => handleChangeText(e, setRua)}
          name="rua"
          value={rua.value}
          label="Rua*"
          error={newPredio && rua.error}
          onBlur={(e) => handleOnBlur(e, setRua, null, newPredio)}
          required={newPredio}
        />

        <RowStyle>
          <TextField
            onChange={(e) => handleChangeText(e, setNumero)}
            name="numero"
            value={numero.value}
            label="Apartamento*"
            type="number"
            error={newPredio && numero.error}
            onBlur={(e) => handleOnBlur(e, setNumero, null, newPredio)}
            md={48}
            required={newPredio}
          />

          <TextField
            name="bairro"
            value={leadAddress.bairro}
            label="Bairro*"
            md={48}
            disabled
          />
        </RowStyle>
      </>
    );
  }

  return (
    <>
      <RowStyle>
        <TextField
          onChange={(e) => handleChangeText(e, setAndar)}
          name="andar"
          value={andar.value}
          label="Andar*"
          error={!newPredio && andar.error}
          onBlur={(e) => handleOnBlur(e, setAndar, null, !newPredio)}
          required={!newPredio}
          md={48}
        />

        <TextField
          onChange={(e) => handleChangeText(e, setNumero)}
          name="numero"
          value={numero.value}
          label="Apartamento*"
          type="number"
          error={!newPredio && numero.error}
          onBlur={(e) => handleOnBlur(e, setNumero, null, !newPredio)}
          md={48}
          required={!newPredio}
        />
      </RowStyle>

      <RowStyle>
        <TextField
          onChange={(e) => handleChangeText(e, setTorre)}
          name="torre"
          value={torre.value}
          label="Torre"
          md={48}
        />

        <TextField
          onChange={(e) => handleChangeText(e, setAla)}
          name="ala"
          value={ala.value}
          label="Ala"
          md={48}
        />
      </RowStyle>
    </>
  );
};

const RowStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;

export default FieldsPredial;
