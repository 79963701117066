import React, { useEffect, useCallback, useState } from "react";
import { useToasts } from "react-toast-notifications";
import styled from "styled-components";

import { IoIosClose } from "react-icons/io";

import { ReactComponent as Logo } from "assets/img/loga_logo.svg";
import { getChannelsByRegion } from "services/channels";
import Loading from "components/loading/Loading.component";

import * as Styles from "./channelsModal.styles";
import FadeIn from "react-fade-in";
import LogaModal from "components/loga-modal/LogaModal.component";
import alert from "assets/img/alert.svg";

const ChannelsModal = ({ toggle, onClose, tvPlan }) => {
  const [channels, setChannels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [focus, setFocus] = useState(
    `Canais LOGA ${tvPlan.replace("LOGA TV ", "")}`
  );
  const [structure, setStructure] = useState(null);

  const [toggleModalErr, setToggleModalErr] = useState(false);
  const [errMessage, setErrMessage] = useState({
    title: "",
    message: "",
  });

  const fetchChannels = useCallback(async () => {
    try {
      const data = await getChannelsByRegion("es");

      if (!data.model) {
        throw new Error("não existe canais");
      }

      let ordenado = data.model;

      ordenado.map((d) => {
        d.canais = d.canais.sort((a, b) => {
          let comp1 = a.categoria || "Outros";
          let comp2 = b.categoria || "Outros";

          return comp1.localeCompare(comp2);
        });
      });

      setChannels(ordenado);

      buildSectionsData(ordenado);
    } catch (err) {
      setToggleModalErr(true);
      setErrMessage({
        ...errMessage,
        title: "Atenção",
        message:
          "No momento não foi possivel encontrar canais para esta região",
      });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchChannels();
  }, [fetchChannels]);

  const buildSectionsData = (data) => {
    const normalize = {};

    data.forEach((element) => {
      normalize[String(element.nome)] = {};

      element.canais.forEach((chanel) => {
        if (
          !normalize[String(element.nome)][String(chanel.categoria || "Outros")]
        ) {
          return (normalize[String(element.nome)][
            String(chanel.categoria || "Outros")
          ] = [chanel]);
        }

        normalize[String(element.nome)][
          String(chanel.categoria || "Outros")
        ].push(chanel);
      });
    });

    setStructure(normalize);
  };

  const capitalize = (text) => {
    return text[0].toUpperCase() + text.slice(1).toLowerCase();
  };

  const buildSectionElements = (selected) => {
    const categorys = Object.keys(structure[selected]).filter((item) => item);

    return categorys.map((category) => (
      <Styles.ChannelSections key={`${focus}-${category}`}>
        <Styles.SectionTitle>{category}</Styles.SectionTitle>
        <Styles.Items>
          {structure[selected][category].map((chanel) => (
            <Styles.ChanelPanel key={`${focus}-${category}-${chanel.nome}`}>
              {chanel.logoimg && (
                <Styles.Thumb src={`/img/tvlogos/${chanel.logoimg}`} />
              )}
            </Styles.ChanelPanel>
          ))}
        </Styles.Items>
      </Styles.ChannelSections>
    ));
  };

  if (!toggle) {
    return null;
  }

  // teste commit 2

  const handleToggleErr = () => setToggleModalErr((old) => !old);

  return (
    <>
      <LogaModal toggle={toggleModalErr} handleTogle={handleToggleErr}>
        <Wrapper>
          <Image src={alert} alt="Mensagem de alerta" />

          <Title>{errMessage.title}</Title>

          <Text>{errMessage.message}</Text>

          <ButtonAccept onClick={handleToggleErr}>Fechar</ButtonAccept>
        </Wrapper>
      </LogaModal>
      <Styles.Overall>
        <Styles.MainContainer>
          <Styles.CloseWrapper>
            <IoIosClose size={30} color="#000000" onClickCapture={onClose} />
          </Styles.CloseWrapper>
          <Styles.LogoWrapper>
            <Logo />
          </Styles.LogoWrapper>

          {loading ? (
            <Loading />
          ) : (
            <>
              <Styles.ButtonsWrapper>
                {channels.map((channelPlan) => {
                  return (
                    <Styles.Button
                      key={channelPlan.nome.replace("Canais LOGA ", "")}
                      focus={focus === channelPlan.nome}
                      onClick={() => setFocus(channelPlan.nome)}
                    >
                      {capitalize(channelPlan.nome.replace("Canais LOGA ", ""))}
                    </Styles.Button>
                  );
                })}
              </Styles.ButtonsWrapper>
              <Styles.ModalContainer>
                {buildSectionElements(focus)}
              </Styles.ModalContainer>
            </>
          )}
        </Styles.MainContainer>
      </Styles.Overall>
    </>
  );
};

const Wrapper = styled.div`
  flex: 1;
  align-items: center;
  flex-direction: column;
  display: flex;
`;

const Image = styled.img`
  width: 70px;
  height: 60px;
`;

const Title = styled.p`
  color: ${({ theme }) => theme.colors.secondary};
  font-family: "Nunito";
  font-size: 1.625rem;
  font-weight: normal;
  text-align: center;
  margin: 20px;
  margin-bottom: 1.3rem;
  line-height: 1.3;
`;

const Text = styled(Title)`
  text-align: center;
  font-size: 1.125rem;
  margin-top: 1.5rem;
  font-weight: bold;
  & strong {
    font-weight: bold;
  }
`;

export const ButtonAccept = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.secondary};
  flex: 1;
  margin: 20px;
  background-color: #ffffff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 11px;
  font-size: 18px;
  padding: 1rem;
  align-self: center;
  width: 226.16px;

  /* &:focus,
  &:hover {
    background-color: #d87e24;
  } */
`;

export default ChannelsModal;
