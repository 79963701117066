import React from "react";
import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";

import "react-perfect-scrollbar/dist/css/styles.css";
// import "bootstrap/dist/css/bootstrap.min.css";

import { setI18nConfig } from "i18n/config";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

setI18nConfig();

const tagManagerArgs = {
  gtmId: "GTM-WW4C8MR",
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
