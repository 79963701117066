// import axios from "axios";
import { post, requestMicroService } from './http'

// const REACT_APP_MS_URL_LEADS = process.env.REACT_APP_MS_URL_LEADS;
// const REACT_APP_MS_PORT_LEADS = process.env.REACT_APP_MS_PORT_LEADS;

// if (!REACT_APP_MS_URL_LEADS) {
//   throw new Error("Endereço da API LEADS não foi definida no .env");
// }

export const saveLeads = async (user, uid = "") => {
  const { data } = await requestMicroService('leads', 'post', `usuario/${uid}`, user)

  return data;
};

export const saveLeadChipDestinyAddress = async (dto, uid) => {
  const { data } = await requestMicroService('leads', 'post', `endereco_entrega/${uid}`, dto)

  return data;
};
