import React, { forwardRef, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import FadeIn from "react-fade-in";
import media from "styled-media-query";

import ShadowButton from "components/shadow-button/ShadowButton.component";
import { formatBRL } from "helpers/formatCurrency";
import { useGlobalState } from "state/ContextProvider";
import PlanSummaryItem from "components/plan-summary-item/PlanSummaryItem.component";
import { useLocationState } from "state/LocationProvider";
import { useToasts } from "react-toast-notifications";
import { savePlanos } from "services/mountCombo";
import ShadowOutlineButton from "components/shadow-outline-button/ShadowOutlineButton.component";
import LogaModal from "components/loga-modal/LogaModal.component";
import alert from "assets/img/alert.svg";

const BoxDesktopSelecteds = () => {
  const { combo, uid } = useGlobalState();
  const { inProgress, setInProgress } = useLocationState();

  const navigation = useHistory();

  const { addToast } = useToasts();
  const [toggleModalErr,setToggleModalErr] = useState(false);
  const [errMessage,setErrMessage] = useState({
    title:"",
    message:""
  });

  const onBack = () => {
    navigation.goBack();
  };

  const getTotal = (items) =>
    items.reduce((prev, curr) => prev + curr.preco, 0);

  const onClickNext = async () => {
    let success = false;
    try {
      if (combo.length) {
        setInProgress(true);
        const ids = combo.map((plan) => plan.id);
        await savePlanos(ids, uid);
        success = true;
      }
    } catch (err) {
      // addToast("Falha ao salvar dados", {
      //   appearance: "error",
      // });
      setToggleModalErr(true);
       setErrMessage({
        ...errMessage,
        title:"Opss... Falha ao salvar",
        message:"Falha ao salvar dados"
      });
      success = false;
    } finally {
      setInProgress(false);

      success && navigation.push("/localizacao");
    }
  };

  const getAccessionItems = (items) => {
    const filtered = items.filter((item) => item?.adesao > 0);

    if (!filtered.length) return null;

    return (
      <AccessionWrapper>
        {filtered.map((item) => (
          <FadeIn key={`access-${item.id}`}>
            <p>
              Será cobrada uma taxa de adesão da {item.nome} no valor de&nbsp;
              <strong>{formatBRL(item.adesao)}</strong> na primeira fatura.
            </p>
          </FadeIn>
        ))}
      </AccessionWrapper>
    );
  };

  const handleToggleErr = () => setToggleModalErr((old) => !old);

  return (
    <>
      <LogaModal toggle={toggleModalErr} handleTogle={handleToggleErr}>
        <Wrapper>
          <Image src={alert} alt="Mensagem de alerta"/>

          <TitleModal>
            {errMessage.title}
          </TitleModal>

          <Text>
            {errMessage.message}
          </Text>

          <ButtonAccept onClick={handleToggleErr}>Fechar</ButtonAccept>
        </Wrapper>
      </LogaModal>

    <Container>
      <Title>Meu Plano</Title>

      {combo.map((plan) => (
        <PlanSummaryItem key={`item-${plan.id}`} plan={plan} />
      ))}
      {!!combo.length && !getAccessionItems(combo) && (
        <FadeIn>
          <Intalation>Adesão grátis</Intalation>
        </FadeIn>
      )}
      <TotalWrapper>
        <span>Valor do plano</span>
        <span>{formatBRL(getTotal(combo))}</span>
      </TotalWrapper>

      {!!combo.length && getAccessionItems(combo)}

      {!!combo.length && (
        <ShadowButton onClick={onClickNext} loading={inProgress}>
          Próximo
        </ShadowButton>
      )}
      <Empty />
      <ShadowOutlineButton onClick={onBack}>Voltar</ShadowOutlineButton>
    </Container>
    </>
  );
};

const Container = styled.section`
  background-color: #fff;
  border-left: 1px solid #eef0f3;
  flex: 1 1 30%;
  height: 100%;
  min-height: 332px;
  display: flex;

  padding: 0 0 0 1.7rem;
  flex-direction: column;
  ${media.lessThan("medium")`
    display: none;
  `}
  position: relative;
`;

const AccessionWrapper = styled.div`
  align-self: stretch;
  margin: 1rem 0;
  font-family: "Nunito";
  font-size: 0.9rem;
  color: ${({ theme }) => theme.colors.secondary};
  font-weight: 400;

  & p {
    margin: 14px 0;
    font-size: 0.9rem !important;
    line-height: 1rem;

    & strong {
      font-weight: bold !important;
    }
  }
`;

export const Title = styled.h2`
  font-size: 1.2rem;
  text-align: left;
  color: ${({ theme }) => theme.colors.secondary};
  font-family: "VAGRounded BT";
  margin-bottom: 1.4rem;
`;

export const Empty = styled.div`
  height: 2rem;
`;

const TotalWrapper = styled.section`
  background-color: ${({ theme }) => theme.colors.input};
  align-self: stretch;
  height: 95px;
  margin-top: 2rem;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  padding: 1rem;
  margin: 1rem 0;

  & span:first-child {
    font-size: 0.9rem;
    text-align: left;
    color: ${({ theme }) => theme.colors.secondary};
    font-family: "VAGRounded BT";
    margin-bottom: 10px;
  }

  & span:last-child {
    font-size: 2rem;
    text-align: left;
    color: ${({ theme }) => theme.colors.compos};
    font-family: "VAGRounded BT";
  }
`;

const Intalation = styled.p`
  color: ${({ theme }) => theme.colors.compos};
  font-family: "VAGRounded BT";
  font-size: 16px;
  text-align: right;
`;

const Wrapper = styled.div`
  flex: 1;
  align-items: center;
  flex-direction: column;
  display: flex;
`;

const Image = styled.img`
  width:70px;
  height:60px;
`;

const TitleModal = styled.p`
  color: ${({ theme }) => theme.colors.secondary};
  font-family: "Nunito";
  font-size: 1.625rem;
  font-weight: normal;
  text-align: center;
  margin: 20px;
  margin-bottom: 1.3rem;
  line-height: 1.3;
`;

const Text = styled(TitleModal)`
  text-align: center;
  font-size:1.125rem;
  margin-top: 1.5rem;
  font-weight: bold;
  & strong {
    font-weight: bold;
  }
`;

export const ButtonAccept = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.secondary};
  flex: 1;
  margin: 20px;
  background-color: #ffffff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 11px;
  font-size: 18px;
  padding: 1rem;
  align-self: center;
  width: 226.16px;

  /* &:focus,
  &:hover {
    background-color: #d87e24;
  } */
`;

export default forwardRef(BoxDesktopSelecteds);
