import React from "react";
import styled from "styled-components";
import media from "styled-media-query";

const SelectField = ({
  label,
  id,
  data,
  propertyValue,
  propertyLabel,
  placeholder,
  onChange,
  value,
  error,
  md = 100,
  sm = 100,
}) => {
  if (!Array.isArray(data)) {
    console.error("data precisa ser uma array");
    return null;
  }

  const buildOptions = () => {
    return data.map((item) => (
      <option
        key={`opt-${propertyValue ? item[propertyValue] : item}`}
        value={propertyValue ? item[propertyValue] : item}
      >
        {propertyLabel ? item[propertyLabel] : item}
      </option>
    ));
  };

  return (
    <FormGroupStyle>
      <LabelStyle htmlFor={id}>{label}</LabelStyle>

      <SelectStyle value={value} onChange={onChange}>
        <option value="" disabled>
          {placeholder ? placeholder : "- Escolha uma data -"}
        </option>
        {buildOptions()}
      </SelectStyle>

      {error ? <ErrorStyle>{error}</ErrorStyle> : null}
    </FormGroupStyle>
  );
};

const FormGroupStyle = styled.div`
  width: ${({ sm }) => String(sm)}%;
  margin: 0.6rem 0;
  position: relative;

  ${media.greaterThan("medium")`
    width: ${({ md }) => String(md)}%;

    &:ntc(:last-child) {
      margin-right: ${({ md }) => (md < 100 ? "13px" : "0")}
    }
  `}
`;

const LabelStyle = styled.label`
  font-size: 14px;
  font-family: "VAGRounded BT";
  color: ${({ theme }) => theme.colors.secondary};
  display: block;
  margin-bottom: 0.8rem;

  ${media.greaterThan("medium")`
    font-size: 18px;
  `}
`;

const SelectStyle = styled.select`
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  height: 6vh;
  max-height: 50px;
  min-height: 40px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.input};
  border-radius: 18px;
  font-family: "Nunito";
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 1rem;
  border: none;
  padding: 0 1rem;

  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.primary};
    box-shadow: 0 0 0 0;
    outline: 0;
  }

  ${media.lessThan("medium")`
    width: 100%;
  `}
`;

const ErrorStyle = styled.small`
  color: ${({ theme }) => theme.colors.red};
  font-family: "Nunito";
  font-size: 0.9rem;
  letter-spacing: 0.5px;
  margin: 0.6rem 0;
  display: block;
`;

const HelpStyle = styled(ErrorStyle)`
  color: ${({ theme }) => theme.colors.primary};
`;

export default SelectField;
