import React from 'react'
import styled from 'styled-components'

const AccordionBody = ({children}) => {
  return (
    <AccordionBodyWrapper>
      {children}
    </AccordionBodyWrapper>
  );
}

const AccordionBodyWrapper = styled.div`
  margin-top: 15px;
  align-self: strech;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export default AccordionBody
