import React from 'react';
import styled from 'styled-components';

import masterCard from 'assets/img/brand_mastercard.png';
import visa from 'assets/img/brand_visa.png';
import amex from 'assets/img/brand_amex.png';
import diners from 'assets/img/brand_diners.png';
import jcb from 'assets/img/brand_jcb.png';
import discover from 'assets/img/brand_discover.png';

import aura from 'assets/img/brand_aura.png';
import elo from 'assets/img/brand_elocard.png';
import hiper from 'assets/img/brand_hiper.png';
import unknow from 'assets/img/brandUnknow.png';

import { RiVisaFill } from 'react-icons/ri';
import { 
    FaCcMastercard,
    FaCcAmex,
    FaCcDinersClub,
    FaCcDiscover,
    FaCcJcb,
    } from 'react-icons/fa';



const Image = styled.img `
    width:40px;
    height:30px;
`;

const BrandCardIcon = ({brand}) => {
  switch (brand) {
    case 'M':
      return <Image src={masterCard} />;

    case 'MasterCard':
      return <Image src={masterCard} />;

    case 'Mastercard':
      return <Image src={masterCard} />;

    case 'A':
      return <Image src={amex} />;

    case 'American Express':
      return <Image src={amex} />;

    case 'D':
      return <Image src={diners} />;

    case 'Diners':
      return <Image src={diners} />;

    case 'R':
      return <Image src={discover} />;

    case 'Discover':
      return <Image src={discover} />;

    case 'J':
      return <Image src={jcb} />;

    case 'JCB':
      return <Image src={jcb} />;

    case 'V':
      return <Image src={visa} />;

    case 'Visa':
      return <Image src={visa} />;

    case 'U': // aura
      return <Image src={aura} />;

    case 'Aura': // aura
      return <Image src={aura} />;

    case 'E': // elo
      return <Image src={elo} />;

    case 'Elo': // elo
      return <Image src={elo} />;

    case 'H': // hipercard
      return <Image src={hiper} />;

    case 'Hipercard': // hipercard
      return <Image src={hiper} />;

    default:
      return <Image src={unknow} />;
  }
};

export default BrandCardIcon;
