// import axios from "axios";
import { requestMicroService } from './http'

// const REACT_APP_MS_URL_LEADS = process.env.REACT_APP_MS_URL_LEADS;
// const REACT_APP_MS_PORT_LEADS = process.env.REACT_APP_MS_PORT_LEADS;

// if (!REACT_APP_MS_URL_LEADS) {
//   throw new Error("Endereço da API LEADS não foi definida no .env");
// }

/**
 *
 * @param {{
    "apartamento": string,
    "andar": number,
    "torre": string,
    "ala": string,
    "cep": string
}} predio
 * @param {string} uid
 */
export const savePredialLocation = async (predio, uid) => {
  const { data } = await requestMicroService('leads', 'post', `endereco_predio/${uid}`, predio)

  return data;
};

/**
 *
 * @param {{
    "predio": "string",
    "numero_andares": "string",
    "numero_apartamentos": "string",
    "torre": "string",
    "ala": "string",
    "rua": "string",
    "bairro": "string",
    "numero": "string"
}} predio
 * @param {string} uid
 */
export const saveInviabialidadePredial = async (predio, uid) => {
  await requestMicroService('leads', 'post', `inviabilidade_predial/${uid}`, predio)
};

/**
 *
 * @param {{
    "cep": string,
    "latitudeCliente": string,
    "longitudeCliente": string
  }} residencie
 * @param {string} uid
 */
export const saveResidencialLocation = async (residencie, uid) => {
  const { data } = await requestMicroService('leads', 'post', `endereco_residencial/${uid}`, residencie)

  return data;
};
