// import axios from "axios";
import { requestMicroService } from './http'

// const REACT_APP_MS_URL_LEADS = process.env.REACT_APP_MS_URL_LEADS;
// const REACT_APP_MS_PORT_LEADS = process.env.REACT_APP_MS_PORT_LEADS;

// if (!REACT_APP_MS_URL_LEADS) {
//   throw new Error("Endereço da API LEADS não foi definida no .env");
// }

export const finallyLead = async (uid, aceito) => {
  const { data } = await requestMicroService('leads', 'get', `finaliza/${uid}`)

  return data;
};
