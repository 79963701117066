// import axios from "axios";
import { requestMicroService } from './http'

// const { REACT_APP_MS_CANAIS } = process.env;

// if (!REACT_APP_MS_CANAIS) {
//   throw new Error("Endereço da API LEADS não foi definida no .env");
// }

// export const getChannelsByRegion = async (region) => {
//   const { data } = await axios.get(`${REACT_APP_MS_CANAIS}`);
//   return data;
// };

export const getChannelsByRegion = async (region) => {
  const { data } = await requestMicroService('channels', 'get', 'canais')
  
  return data;
};
