import React, { useCallback, useState, useEffect, useRef } from "react";
import styled from "styled-components";
import media from "styled-media-query";
import { addYears, format } from "date-fns";

import PrevNexBox from "components/prev-next-box/PrevNextBox.component";
import { useLocationState } from "state/LocationProvider";
import { useGlobalState } from "state/ContextProvider";
import BrandCardIcon from "components/brandCardIcon/BrandCardIcon";
import {
  isValid,
  isExpirationDateValid,
  isSecurityCodeValid,
  getCreditCardNameByNumber,
} from "creditcard.js";
import { saveMembershipCard } from "services/saveCard";
import LogaModal from "components/loga-modal/LogaModal.component";
import alert from "assets/img/alert.svg";

const BRAND_TYPES = {
  AmericanExpress: "amex",
  Diners: "diners",
  Elo: "elo",
  Hipercard: "hipercard",
  JCB: "jcb",
  MasterCard: "master",
  Mastercard: "master",
  Discover: "discover",
  Aura: "aura",
  Visa: "visa",
};

const TYPE_PAYMENTS = ["Cartão de crédito", "Boleto"];

const MINIMIUM_VALUE = 50;

const getPaymentValue = (items) =>
  items.reduce((prev, current) => prev + (current?.adesao || 0), 0);

export default function Membership() {
  const [number, setNumber] = useState("");
  const [validate, setValidate] = useState("");
  const [cvv, setCvv] = useState("");
  const [name, setName] = useState("");
  const [cardBrand, setCardBrand] = useState("");
  const [valueChange, setValueChange] = useState("");

  const [typePaymentChosen, setTypePaymentChosen] = useState("");

  const [numberError, setNumberError] = useState("");
  const [validateError, setValidateError] = useState("");
  const [cvvError, setCvvError] = useState("");
  const [nameError, setNameError] = useState("");
  const [selectPaymentError, setSelectPaymentError] = useState("");

  const [toggleModalErr, setToggleModalErr] = useState(false);
  const [errMessage, setErrMessage] = useState({
    title: "",
    message: "",
  });

  const { combo, uid } = useGlobalState();

  const value = useRef(getPaymentValue(combo)).current;

  const { onClickNextStep, setMembershipPayment, setInProgress } =
    useLocationState();

  const mountPortions = () => {
    const maxPartial = new Array(4).fill();

    return maxPartial.fill().map((_, index) => {
      const divider = index + 1;
      const parcialValue = value / divider;

      return `${divider} x R$${parcialValue.toFixed(2)} ${
        divider === 1 ? "(a vista)" : ""
      }`;
    });
  };

  const getPortions = () => {
    // valor mínimo para parcelamento
    return value >= MINIMIUM_VALUE
      ? mountPortions()
      : [`1 x R$${value.toFixed(2)} (a vista)`];
  };

  const isEmpty = (items) => {
    return items.some((item) => !item.trim().length);
  };

  // Definir para receber apenas números
  const handleKey = useCallback((e) => {
    let { value } = e.currentTarget;
    value = value.replace(/\D/gi, "");
    e.currentTarget.value = value;
  }, []);

  const handleValidate = (date) => {
    let { value } = date.target;

    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{2})(\d{4})$/gi, "$1/$2");

    setValidate(value);
  };

  useEffect(() => {
    const brand =
      number.length > 3
        ? getCreditCardNameByNumber(number.replace(/\D/gi, ""))
        : "";

    setCardBrand(brand);
  }, [number]);

  const handlePayment = async (e) => {
    e.preventDefault();

    if (typePaymentChosen === "") {
      setToggleModalErr(true);
      return setErrMessage({
        ...errMessage,
        title: "Ops.. ",
        message: "Por favor, escolha a forma de pagamento",
      });
    }

    if (typePaymentChosen === "0") {
      try {
        const creditCardNumber = number?.replace(/\D/gi, "");

        if (isEmpty([number])) setNumberError("Este campo é obrigatório");
        else if (!isValid(creditCardNumber))
          setNumberError("O número do cartão informado não é valido.");
        else setNumberError("");

        if (isEmpty([name])) setNameError("Este campo é obrigatório");
        else setNameError("");

        if (isEmpty([cvv])) setCvvError("Este campo é obrigatório");
        else if (!isSecurityCodeValid(creditCardNumber, cvv))
          setCvvError("O CVV informado não está correto.");
        else setCvvError("");

        if (isEmpty([validate])) setValidateError("Este campo é obrigatório");
        else if (!isExpirationDateValid(...validate.split("/")))
          setValidateError("O cartão informado está fora da data de validade.");
        else setValidateError("");

        if (!valueChange) setSelectPaymentError("Este campo é obrigatório");
        else setSelectPaymentError("");

        if (
          isEmpty([number, name, cvv, validate]) ||
          !valueChange ||
          !isValid(creditCardNumber) ||
          !isSecurityCodeValid(creditCardNumber, cvv) ||
          !isExpirationDateValid(...validate.split("/"))
        )
          return setInProgress(false);

        setInProgress(true);

        const card = {
          nomeComprador: name,
          valor: value,
          numeroCartao: creditCardNumber,
          dataExpiracao: validate,
          codigoSeguranca: cvv,
          bandeira: BRAND_TYPES[getCreditCardNameByNumber(creditCardNumber)],
          parcelas: valueChange,
        };

        await saveMembershipCard(card, uid);

        setMembershipPayment({ value: card, error: "" });

        setInProgress(false);
        onClickNextStep();
      } catch (err) {
        setInProgress(false);
      }
    } else {
      setInProgress(false);
    }

    onClickNextStep(e);
  };

  const handleChangePaymentChosen = (e) => {
    const typePayment = e.target.value;
    setTypePaymentChosen(typePayment);
  };

  const handleChange = (e) => {
    const valueChange = e.target.value;
    setValueChange(valueChange);
  };

  const handleToggleErr = () => setToggleModalErr((old) => !old);

  return (
    <>
      <Container>
        <LogaModal toggle={toggleModalErr} handleTogle={handleToggleErr}>
          <Wrapper>
            <Image src={alert} alt="Mensagem de alerta" />

            <Title>{errMessage.title}</Title>

            <Text>{errMessage.message}</Text>

            <ButtonAccept onClick={handleToggleErr}>Fechar</ButtonAccept>
          </Wrapper>
        </LogaModal>
        <Form onSubmit={handlePayment}>
          <LabelSelect htmlFor="id_typePayment">
            Escolha a forma de pagamento
          </LabelSelect>
          <SelectTypePayment
            value={typePaymentChosen}
            onChange={handleChangePaymentChosen}
            id="id_typePayment"
          >
            <option value="" disabled>
              -Escolha como pagar-
            </option>
            {TYPE_PAYMENTS.map((types, index) => {
              return (
                <option value={index} key={index}>
                  {types}
                </option>
              );
            })}
          </SelectTypePayment>

          {typePaymentChosen === "0" ? (
            <ContentCard>
              <LabelGroup>Dados do cartão</LabelGroup>
              <WrapperInput>
                <InputCustom
                  type="text"
                  maxLength={19}
                  name="numeroCartao"
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                  placeholder="Número do cartão"
                  onKeyUp={handleKey}
                />
                <PositionAbsolute>
                  <BrandCardIcon brand={cardBrand} />
                </PositionAbsolute>
              </WrapperInput>
              <FieldError>{numberError}</FieldError>
              <Rows>
                <Column>
                  <Input
                    name="dataExpiracao"
                    maxLength={7}
                    value={validate}
                    onChange={handleValidate}
                    placeholder={`Validade ( ex: ${format(
                      addYears(new Date(), 2),
                      "MM/yyyy"
                    )} )`}
                    // placeholder="Validade - Apenas números"
                    //   onKeyUp={handleKeyDate}
                  />
                  <FieldError>{validateError}</FieldError>
                </Column>
                <Column>
                  <Input
                    type="text"
                    maxLength={3}
                    name="codigoSeguranca"
                    value={cvv}
                    onChange={(e) => setCvv(e.target.value)}
                    placeholder="CVV"
                    onKeyUp={handleKey}
                  />
                  <FieldError>{cvvError}</FieldError>
                </Column>
              </Rows>
              <Input
                type="text"
                name="nomeComprador"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Nome do titular"
              />
              <FieldError>{nameError}</FieldError>

              <LabelSelect htmlFor="id_select">Parcelas</LabelSelect>
              <SelectFormPayment
                value={valueChange}
                onChange={handleChange}
                id="id_select"
              >
                <option value="" disabled>
                  - Escolha a quantidade de parcelas -
                </option>

                {getPortions().map((parcela, index) => (
                  <option value={index} key={index}>
                    {parcela}
                  </option>
                ))}
              </SelectFormPayment>
              <FieldError>{selectPaymentError}</FieldError>
            </ContentCard>
          ) : (
            ""
          )}
        </Form>
      </Container>
      <PrevNexBox overwriteNext={handlePayment} />
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 3rem 0;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1.8rem;
`;

const WrapperInput = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 47px;
  box-sizing: border-box;

  background: #ededed;
  border-radius: 3px;

  margin: 6px auto;
`;

const InputCustom = styled.input`
  width: 100%;
  height: inherit;

  background: inherit;
  padding-left: 23px;

  color: ${({ theme }) => theme.colors.secondary};
  border-radius: 3px;
  font-weight: bold;

  /* &:focus {
      outline: none;
      border: none;
      box-shadow: none;
    } */

  &:focus {
    border: 2px solid ${({ theme }) => theme.colors.primary};
  }

  ::placeholder,
  ::-webkit-input-placeholder {
    font: normal normal normal 15px/20px Nunito;
    color: #525252;
  }
  :-ms-input-placeholder {
    font: normal normal normal 15px/20px Nunito;
    color: #525252;
  }
`;

const PositionAbsolute = styled.div`
  position: absolute;
  top: 222px;
  right: 20px;
  ${media.greaterThan("medium")`
        top: 236px;
    `}
`;

const Input = styled.input`
  width: 100%;
  height: 47px;
  box-sizing: border-box;

  background: #ededed;
  border-radius: 3px;
  font-weight: bold;

  color: ${({ theme }) => theme.colors.secondary};

  margin: 6px auto;
  padding-left: 23px;

  ::placeholder,
  ::-webkit-input-placeholder {
    font: normal normal normal 15px/20px Nunito;
    color: #525252;
  }
  :-ms-input-placeholder {
    font: normal normal normal 15px/20px Nunito;
    color: #525252;
  }
`;

const FieldError = styled.span`
  font: normal normal normal 15px/20px Nunito;
  color: #f44336;
`;

const Rows = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  div:first-child {
    width: 70%;
    margin-right: 17px;
  }
  div:last-child {
    width: 30%;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const LabelSelect = styled.label`
  font-size: 14px;
  font-family: "VAGRounded BT";
  color: ${({ theme }) => theme.colors.secondary};
  display: block;
  margin-top: 1.2rem;
  margin-bottom: 0.8rem;

  ${media.greaterThan("medium")`
        font-size: 18px;
    `}
`;

const SelectFormPayment = styled.select`
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  height: 6vh;
  max-height: 50px;
  min-height: 40px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.input};
  border-radius: 18px;
  font-family: "Nunito";
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 1rem;
  border: none;
  padding: 0 1rem;
`;

const SelectTypePayment = styled.select`
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  height: 6vh;
  max-height: 50px;
  min-height: 40px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.input};
  border-radius: 18px;
  font-family: "Nunito";
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 1rem;
  border: none;
  padding: 0 1rem;
  margin-bottom: 30px;
`;

const LabelGroup = styled.h3`
  font-size: 14px;
  font-family: "VAGRounded BT";
  color: ${({ theme }) => theme.colors.secondary};
  display: block;
  margin-top: 1.2rem;
  margin-bottom: 0.8rem;

  ${media.greaterThan("medium")`
        font-size: 18px;
    `}
`;

const ContentCard = styled.div``;

const Image = styled.img`
  width: 70px;
  height: 60px;
`;

const Title = styled.p`
  color: ${({ theme }) => theme.colors.secondary};
  font-family: "Nunito";
  font-size: 1.625rem;
  font-weight: normal;
  text-align: center;
  margin: 20px;
  margin-bottom: 1.3rem;
  line-height: 1.3;
`;

const Wrapper = styled.div`
  flex: 1;
  align-items: center;
  flex-direction: column;
  display: flex;
`;

const Text = styled(Title)`
  text-align: center;
  font-size: 1.125rem;
  margin-top: 1.5rem;
  font-weight: bold;
  & strong {
    font-weight: bold;
  }
`;

export const ButtonAccept = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.secondary};
  flex: 1;
  margin: 20px;
  background-color: #ffffff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 11px;
  font-size: 18px;
  padding: 1rem;
  align-self: center;
  width: 226.16px;

  /* &:focus,
  &:hover {
    background-color: #d87e24;
  } */
`;
