import { saveLeads } from "./leads";

/**
 *
 * @param {{
    "nome": string,
    "email": string,
    "telefone": string,
    "cep": string
}} newLead
*
* @returns {{
  "id": number,
  "uid": string,
  "value": {
      "data": {
          "usuario": {
              "nome": string,
              "email": string,
              "telefone": string,
              "telefone2": string,
              "cep": string,
          }
      }
  }
}} data
 */

export const createLead = async (newLead) => {
  const data = await saveLeads(newLead);

  return data;
};
