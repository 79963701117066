import React, { useState } from "react";
import {HiArrowCircleLeft,HiArrowCircleRight} from 'react-icons/hi';
import Carousel, {
  slidesToShowPlugin,
  slidesToScrollPlugin,
  arrowsPlugin,
  
  Dots,
} from "@brainhubeu/react-carousel";
// import "@brainhubeu/react-carousel/lib/style.css";
import "./style.css";
import styled from "styled-components";

const CardList = ({ list, buildCard }) => {
  const [value, setValue] = useState(0);

  const onChangeValue = (value) => {
    setValue(value);
  };

  return (
    <>
    {/* Se a lista for maior que um listar  os items com seta */}
    { list.length > 1 ? 

      <Carousel
        arrows
        value={value}
        itemWidth={200}
        onChange={onChangeValue}
        breakpoints={{
          640: {
            plugins: [
              { 
                resolve: slidesToShowPlugin,
                options: {
                  numberOfSlides: 1,
                },
              },
            ],
          },
          900: {
            plugins: [
              {
                resolve: slidesToShowPlugin,
                options: {
                  numberOfSlides: 2,
                },
              },
            ],
          },
        }}
        plugins={[
          "centered",
          "infinite",
          "arrows",
          "clickToChange",
          {
            resolve: slidesToShowPlugin,
            options: {
              numberOfSlides: 3,
            },
          },
        ]}
      >
        {list.map(buildCard)}
      </Carousel>

      :

      <Carousel 
      className={"noArrows"} 
      value={value}
      itemWidth={200}
      onChange={onChangeValue}
      breakpoints={{
        640: {
          plugins: [
            {
              resolve: slidesToShowPlugin,
              options: {
                numberOfSlides: 1,
              },
            },
          ],
        },
        900: {
          plugins: [
            {
              resolve: slidesToShowPlugin,
              options: {
                numberOfSlides: 2,
              },
            },
          ],
        },
      }}
      plugins={[
        "centered",
        "infinite",
        "arrows",
        "clickToChange",
        {
          resolve: slidesToShowPlugin,
          options: {
            numberOfSlides: 3,
          },
        },
      ]}
    >
      {list.map(buildCard)}
    </Carousel>

  }

      <DotsWrapper>
        <Dots value={value} onChange={onChangeValue} number={list.length} />
      </DotsWrapper>
    </>
  );
};

const DotsWrapper = styled.div`
  margin: 0 auto;
  margin-top: 0.3rem;

  & ul {
    & li {
      width: auto !important;
      min-width: 3px !important;
      max-width: auto !important;

      & button::before {
        background: ${({ theme }) => theme.colors.secondary};
      }
    }
  }
`;

const Arrows = styled.button`
    display:flex;
    justify-content:center;
    align-items:center;
    cursor:pointer;
    

    width:30px;
    height:30px;
    border-radius:30px;
  
`;

export default CardList;
