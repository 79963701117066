import axios from 'axios';

const KEY = process.env.REACT_APP_MAP_KEY;

export const getLocationFromAddress = async (address) => {
  const urlSearch = address.replace(/\s+/ig, '+');

  const {data} = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${urlSearch}&key=${KEY}`);

  if (data.status !== 'OK') {
    return [];
  }

  return data.results;
}


export const getLocationFromLatLng = async (lat, lng) => {
  const {data} = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${lat},${lng}&key=${KEY}`);

  if (data.status !== 'OK') {
    return [];
  }

  return data.results;
}
