import React from 'react';
import styled from 'styled-components';
import media from "styled-media-query";

const SelectSearchButton = ({onClick, text = '', type = 'fill'}) => {

    if (type === 'line') {
        return <ButtonLine onClick={onClick}>{text}</ButtonLine>;
    }

    return <ButtonFill onClick={onClick}>{text}</ButtonFill>;
}

const ButtonFill = styled.button`
    width: 100%;
    height: 40px;
    border-radius: 13px;
    background-color: ${({theme}) => theme.colors.primary};
    color: ${({theme}) => theme.colors.white};
    font-size: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: 'VAGRounded BT';

    ${media.greaterThan('medium')`
      width: 22vw;
      font-size: 1rem;
    `}
`;

const ButtonLine = styled(ButtonFill)`
    border: 2px solid #F5F5F5;
    height: 40px;
    width: 100%;
    font-size: 0.8rem;
    display: flex;
    background-color: transparent;
    font-family: 'VAGRounded BT';
    color: hsl(0,0%,50%);
    transition: background-color 0.2s ease;

    &:hover {
      background-color: #EDEDED;
    }

    ${media.greaterThan('medium')`
      width: 22vw;
      font-size: 1rem;
    `}
`;

export default SelectSearchButton;
