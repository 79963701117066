import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import {
  handleChangeText,
  handleChangeTextMasked,
  handleOnBlur,
} from "helpers/inputChanges";
import { isEmpty, validateEmail } from "helpers/validateFields";
import { useGlobalState } from "state/ContextProvider";

import MainLayout from "layouts/Main.layout";

import Step from "components/step/Step.component";
import Row from "components/row/Row.component";
import Form from "components/form/Form.component";
import TextField from "components/text-field/TextField.component";
import SelectRadio from "components/select-radio/SelectRadio.component";
import { getPlansByIbge } from "services/plans";
import { getCEPAddress } from "services/address";
import ShadowButton from "components/shadow-button/ShadowButton.component";
import { getLocationFromAddress } from "services/geocoding";
import { useLocationState } from "state/LocationProvider";
import { createLead } from "services/personalData";
import LogaModal from "components/loga-modal/LogaModal.component";
import alert from "assets/img/alert.svg";


function PersonalData() {
  const [loading, setLoading] = useState(false);
  const [cepError, setCepError] = useState(false);
  const [toggleModalErr,setToggleModalErr] = useState(false);
  const [errMessage,setErrMessage] = useState({
    title:"",
    message:""
  });

  const {
    name,
    email,
    phone,
    cep,
    ibge,
    leadAddress,
    isBusiness,
    setName,
    setEmail,
    setPhone,
    setCep,
    setCity,
    setSupported,
    setIbge,
    setLeadAddres,
    setChipDestiny,
    setIsBusiness,
    setUid,
    setCombo,
  } = useGlobalState();

  const { setCoords } = useLocationState();


  const navigation = useHistory();

  useEffect(() => {
    window.dataLayer.push({
      event: "pageview",
      page: {
        url:
          document.location.origin +
          document.location.pathname +
          document.location.search,
        title: "Dados pessoais",
      },
    });
  }, []);

  useEffect(() => {
    setCombo([]);
    saveCep();
  }, [cep]);

  const saveCep = async () => {
    try {
      setLoading(true);

      const clearCep = cep.value.replace(/\D/gi, "");

      if (clearCep.length > 8) setCep({ value: "", error: "" });
      if (!(clearCep.length === 8 && !loading)) return;

      const result = await getCEPAddress(cep.value);

      if (result.erro) {
        setCepError(true);
        throw new Error("");
      }

      const addresses = await getLocationFromAddress(
        `${result.data.endereco} - ${result.data.bairro}, ${result.data.cidade} - ES, ${result.data.cep}, Brazil`
      );
      if (!addresses.length) throw new Error();

      const { geometry } = addresses[0];

      setCoords({
        lat: geometry.location.lat,
        lon: geometry.location.lng,
      });

      setCity(result.data.cidade);
      setIbge(result.data.ibge);
      setLeadAddres(result.data);
      setChipDestiny((old) => {
        return {
          ...old,
          ...result.data,
        };
      });

      setCepError(false);
    } catch (err) {
      setToggleModalErr(true);
      setErrMessage({
        ...errMessage,
        title:err?.response?.data?.message || "CEP inválido.",
        message:""
      });
    } finally {
      setLoading(false);
    }
  };

  // const checkInvalidDDDPhone = ({ phoneNumber }) => {
  //   const validDDDs = ['27', '28', '73'];
  //   const currentDDD = phoneNumber.substring(1, 3);
  //   const currentDDDIsInvalid = !(validDDDs.includes(currentDDD));

  //   if (currentDDDIsInvalid) {
  //     setPhone({ error: 'Digite um DDD válido (27, 28 ou 73)' })
  //   };

  //   return currentDDDIsInvalid;
  // };

  const onSubmit = async (e) => {
    let success = false;
    try {
      setLoading(true);

      e.preventDefault();
      e.stopPropagation();

      const haveEmptyField = isEmpty([
        [name, email, phone, cep],
        [setName, setEmail, setPhone, setCep],
      ]);

      if (
        haveEmptyField ||
        name.error ||
        email.error ||
        phone.error ||
        cep.error
      ) {
        setLoading(false);
         setToggleModalErr(true);
         return setErrMessage({
          ...errMessage,
          title:"Atenção",
          message:"Preencha todos os campos corretamente."
        });
      }

      // if (checkInvalidDDDPhone({ phoneNumber: phone.value })) return;

      if (!leadAddress) return;

      if (cepError) {
        setLoading(false);
        setToggleModalErr(true);
         return setErrMessage({
          ...errMessage,
          title:"CEP inválido",
          message:"Informe um CEP válido."
        });
      }

      let tipoPessoa = isBusiness ? 0 : 1;

      let zipNumber = cep.value.replace("-","");

      await getPlansByIbge(ibge, tipoPessoa, zipNumber);

      setSupported(true);

      const { uid } = await createLead({
        nome: name.value,
        email: email.value,
        telefone: phone.value.replace(/\D/gi, ""),
        telefone2: phone.value.replace(/\D/gi, ""),
        cep: cep.value.replace(/\D/gi, ""),
      });

      setUid(uid);

      success = true;
    } catch (err) {
      success = false;
 
      setToggleModalErr(true);
      setErrMessage({
          ...errMessage,
          title:err?.response?.data?.message || "Ops.. Falha de comunicação",
          message:"Falha ao se comunicar, tente novamente."
        });
      
      setSupported(false);

      if (err?.response?.data?.message) {
        setLoading(false);
        return navigation.push("/sem-suporte");
      }
    } finally {
      setLoading(false);
      success && navigation.push("/selecionar-produtos");
    }
  };

  const handleOnBlurName = (e) => {
    const value = e.target.value.trim();

    const regex = /^[a-zA-Z]{2,30}(\s[a-zA-Z]{2,30})+$/gi;

    const valid = regex.test(value);

    if (!valid) {
      setName({
        value,
        error: "Digite um nome válido.",
      });
      return;
    }

    setName({
      value,
      error: "",
    });

    handleChangeText(e, setName)
    handleOnBlur(e, setName);
  };

  const handleChangePeopleType = (e) => {
    setIsBusiness((oldState) => !oldState);
  };

  const handleToggleErr = () => setToggleModalErr((old) => !old);

  return (
    <>
    <LogaModal toggle={toggleModalErr} handleTogle={handleToggleErr}>
        <Wrapper>
          <Image src={alert} alt="Mensagem de alerta"/>

          <Title>
            {errMessage.title}
          </Title>

          <Text>
            {errMessage.message}
          </Text>

          <ButtonAccept onClick={handleToggleErr}>Fechar</ButtonAccept>
        </Wrapper>
    </LogaModal>
    <MainLayout title="Dados pessoais">
      <Step title="Eu quero a Loga para" number={1} />
      <ContainerWrapper>
        <Row>
          <SelectRadio
            value={isBusiness ? false : true}
            selected={isBusiness ? false : true}
            onChange={handleChangePeopleType}
            title={"Minha casa"}
          />
          <SelectRadio
            value={isBusiness ? true : false}
            selected={isBusiness ? true : false}
            onChange={handleChangePeopleType}
            title={"Minha empresa"}
          />
        </Row>
      </ContainerWrapper>

      <Step 
        title={isBusiness ? 
          "Informe seus dados e vamos escolher o plano ideal pra sua empresa"
          :
          "Informe seus dados e vamos escolher o plano ideal pra sua casa"
        }
        number={2} 
        />

      <Form onSubmit={onSubmit}>
        <TextField
          label={isBusiness ? "Razão social" : "Nome completo"}
          id="name"
          name="name"
          value={name.value}
          required
          onChange={(e) => {
            const bkp = e;
            bkp.target.value = bkp.target.value.toUpperCase();
            handleChangeText(bkp, setName);
          }}
          onBlur={handleOnBlurName}
          error={name.error}
        />

        <TextField
          label="E-mail"
          id="email"
          name="email"
          required
          value={email.value}
          onChange={(e) => handleChangeText(e, setEmail)}
          onBlur={(e) => handleOnBlur(e, setEmail, validateEmail)}
          error={email.error}
        />

        <TextField
          label="Telefone de contato"
          id="phone"
          name="phone"
          required
          helper="Nosso time vai utilizar esse número pra entrar em contato com você"
          value={phone.value}
          onChange={(e) =>
            handleChangeTextMasked(e, setPhone, "(99) 99999-9999")
          }
          onBlur={(e) => handleOnBlur(e, setPhone)}
          error={phone.error}
        />

        <TextField
          md={30}
          label="CEP"
          id="cep"
          required
          name="cep"
          value={cep.value}
          onChange={(e) => handleChangeTextMasked(e, setCep, "99999-999")}
          // onBlur={(e) => handleOnBlur(e, setCep)}
          error={cep.error}
        />

        <ButtonWrapper>
          <ShadowButton loading={loading} disable={cepError}>
            Próximo
          </ShadowButton>
        </ButtonWrapper>
      </Form>
    </MainLayout>
    </>
  );
}

const ButtonWrapper = styled.div`
  margin: 1rem 0;
  align-self: stretch;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContainerWrapper = styled.div`
  margin-bottom: 2.2rem;
  display: flex;
  justify-content: flex-start;
`;

const Wrapper = styled.div`
  flex: 1;
  align-items: center;
  flex-direction: column;
  display: flex;
`;

const Image = styled.img`
  width:70px;
  height:60px;
`;

const Title = styled.p`
  color: ${({ theme }) => theme.colors.secondary};
  font-family: "Nunito";
  font-size: 1.625rem;
  font-weight: normal;
  text-align: center;
  margin: 20px;
  margin-bottom: 1.3rem;
  line-height: 1.3;
`;

const Text = styled(Title)`
  text-align:center;
  font-size:1.125rem;
  margin-top: 1.5rem;
  font-weight: bold;
  & strong {
    font-weight: bold;
  }
`;

export const ButtonAccept = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.secondary};
  flex: 1;
  margin: 20px;
  background-color: #ffffff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 11px;
  font-size: 18px;
  padding: 1rem;
  align-self: center;
  width: 226.16px;

  /* &:focus,
  &:hover {
    background-color: #d87e24;
  } */
`;

export default PersonalData;
