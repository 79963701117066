// import axios from "axios";
import { requestMicroService } from './http'

// const REACT_APP_MS_URL_LEADS = process.env.REACT_APP_MS_URL_LEADS;
// const REACT_APP_MS_PORT_LEADS = process.env.REACT_APP_MS_PORT_LEADS;
// const REACT_APP_MS_URL_KONG = process.env.REACT_APP_MS_URL_KONG;

// if (!REACT_APP_MS_URL_LEADS) {
//   throw new Error("Endereço da API LEADS não foi definida no .env");
// }

// if (!REACT_APP_MS_URL_KONG) {
//   throw new Error("REACT_APP_MS_URL_KONG não foi definida no .env");
// }

// export const saveIncremetalUserData = async (user, uid) => {
//   const { data } = await axios.post(
//     `${REACT_APP_MS_URL_LEADS.replace(
//       "{port}",
//       REACT_APP_MS_PORT_LEADS
//     )}/usuario/${uid}`,
//     user
//   );

//   return data;
// };

export const saveIncremetalUserData = async (user, uid) => {
  const { data } = await requestMicroService('leads', 'post', `usuario/${uid}`, user)

  return data;
};

// export const saveInvoiceDate = async (date, uid) => {
//   const { data } = await axios.get(
//     `${REACT_APP_MS_URL_LEADS.replace(
//       "{port}",
//       REACT_APP_MS_PORT_LEADS
//     )}/agendamento_fatura/${uid}/${date}`
//   );

//   return data;
// };

export const saveInvoiceDate = async (date, uid) => {
  const { data } = await requestMicroService('leads', 'get', `agendamento_fatura/${uid}/${date}`)

  return data;
};

// export const savePortability = async (
//   portabilidade,
//   numero,
//   operadora,
//   uid
// ) => {
//   const { data } = await axios.post(
//     `${REACT_APP_MS_URL_LEADS.replace(
//       "{port}",
//       REACT_APP_MS_PORT_LEADS
//     )}/portabilidade/${uid}`,
//     {
//       operadora,
//       numero,
//       portabilidade: portabilidade ? "sim" : "nao",
//     }
//   );

//   return data;
// };

export const savePortability = async (
  portabilidade,
  numero,
  operadora,
  uid
) => {
  const { data } = await requestMicroService('leads', 'post', `portabilidade/${uid}`, {
      operadora,
      numero,
      portabilidade: portabilidade ? "sim" : "nao",
    }
  );

  return data;
};

// export const saveMobilePortability = async (dto, uid) => {
//   const { data } = await axios.post(
//     `${REACT_APP_MS_URL_LEADS.replace(
//       "{port}",
//       REACT_APP_MS_PORT_LEADS
//     )}/telefonia_movel/${uid}`,
//     dto
//   );

//   return data;
// };

export const saveMobilePortability = async (dto, uid) => {
  const { data } = await requestMicroService('leads', 'post', `telefonia_movel/${uid}`, dto)

  return data;
};

// export const getExpirationDays = async () => {
//   const { data } = await axios.get(
//     `${REACT_APP_MS_URL_KONG}/loga/v1/assine/invoices/expiration_days`
//   );

//   return data.days;
// };

export const getExpirationDays = async () => {
  const { data } = await requestMicroService('invoices', 'get', `expiration_days`)

  return data.days;
};
