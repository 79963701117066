import React from "react";
import styled from "styled-components";
import PerfectScrollbar from "react-perfect-scrollbar";
// import media from "styled-media-query";

const Modal = ({ title, children, footer: Footer, open, onClose }) => {
  if (!open) return null;

  return (
    <ModalBlur>
      <ModalContainer>
        <Top>
          <ButtonClose onClick={onClose}>
            <Stick />
            <Stick />
          </ButtonClose>
        </Top>

        <ModalTitle>{title}</ModalTitle>
        <ScrollContainer>
          <ModalBody>{children}</ModalBody>
        </ScrollContainer>
        <ModalFooter>{!!Footer && <Footer />}</ModalFooter>
      </ModalContainer>
    </ModalBlur>
  );
};

const ModalBlur = styled.div`
  background: rgba(
    255,
    255,
    255,
    0.2
  ); // Make sure this color has an opacity of less than 1
  backdrop-filter: blur(8px); // This be the blur
  height: 100vh;
  width: 100vw;
  max-width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const ModalContainer = styled.div`
  padding: 2.3rem 1rem;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  position: relative;
  box-shadow: ${({ theme }) => theme.colors.shadow};
  margin: 1rem;
`;

const ScrollContainer = styled(PerfectScrollbar)`
  position: relative;
  max-width: 700px;
  max-height: 400px;
`;

const ModalTitle = styled.h2`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.secondary};
  font-family: "VAGRounded BT";
  margin-bottom: 1.5rem;
  text-align: center;
`;

const Stick = styled.span`
  width: 20px;
  height: 3px;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.colors.secondary};
  display: inline-block;
  position: absolute;
`;

const Top = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
`;

const ButtonClose = styled.button`
  height: 30px;
  width: 30px;
  display: flex;
  flex-direction: center;
  align-items: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  & ${Stick}:first-child {
    transform: rotate(45deg);
  }

  & ${Stick}:last-child {
    transform: rotate(-45deg);
  }
`;

const ModalBody = styled.section`
  margin: 1rem 0;
  line-height: 1rem;
`;

const ModalFooter = styled.footer`
  margin-top: 24px;
`;

export default Modal;
