export const isEmpty = (fields, setFunctions) => {
  let empty = false;

  fields.forEach((item, index) => {
    if (item.value === '') {
      empty = true;
      setFunctions[index]({...item, error: 'Esse campo é obrigatório'});
    }
  });

  return empty;
};

export const isNumber = (fields, setFunctions) => {
  let valid = true;

  fields.forEach((item, index) => {
    if (isNaN(item.value)) {
      valid = false;
      setFunctions[index]({value: '', error: 'Valor deve ser um número'});
    }
  });

  return valid;
};

export const validateEmail = (fieldValue) => {
  const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i

  return regex.test(fieldValue);
}
