import styled from "styled-components";
import media from "styled-media-query";
import Rodal from "rodal";

export const LogaModal = styled(Rodal).attrs({
  customStyles: {
    borderRadius: 19,
    paddingTop:"2rem",
    paddingRight:"5rem",
    paddingBottom:"2rem",
    paddingLeft:"5rem",
    width: "auto",
    maxWidth: 700,
    height: "auto",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    inset: "auto",
    margin: 20,
    background:'#fff'
  },

  style: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },



  showCloseButton: false,
})
  
``;

export const Wrapper = styled.div`
  & .rodal.rodal-fade-enter {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;

    backdrop-filter: blur(8px); 
    height: 100vh;
    width: 100vw;
    max-width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9999;
  }
`;