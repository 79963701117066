// import axios from "axios";
import { requestMicroService } from './http'

// const MS_URL_PLANS = process.env.REACT_APP_MS_PLANS;
// const { REACT_APP_MS_PORT_PLANOS } = process.env;

// if (!MS_URL_PLANS) {
//   throw new Error("Endereço da API de Planos não foi definida no .env");
// }

export const getPlansByIbge = async (ibge, isNormalLead = 1, cep) => {
  const { data } = await requestMicroService('plans', 'get', `search_plans/${ibge}?pessoaFisica=${isNormalLead}&zipcode=${cep}`)

  return data;
};
