import React from "react";
import styled from "styled-components";

const Text = ({ label, describe }) => {
  return (
    <TextWrapperStyle>
      <LabelStyle>{label}</LabelStyle>
      <Describe>{describe}</Describe>
    </TextWrapperStyle>
  );
};

const TextWrapperStyle = styled.section`
  margin: 0.6rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const LabelStyle = styled.h4`
  font-size: 0.9rem;
  font-family: "VAGRounded BT";
  margin-bottom: 0.6rem;
  text-align: left;
`;

const Describe = styled.p`
  font-size: 0.9rem;
  font-family: "Nunito";
  text-align: left;
  line-height: 1rem;
`;

export default Text;
