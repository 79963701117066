import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";

import MainContent from "components/main-content/MainContent.component";

const MainLayout = ({
  children,
  title = "Loga - Montar Plano",
  transparent,
}) => {
  return (
    <Container>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <MainContent transparent={transparent}>{children}</MainContent>
    </Container>
  );
};

const Container = styled.section`
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export default MainLayout;
