import styled, { keyframes } from "styled-components";
import media from "styled-media-query";
import PerfectScrollbar from "react-perfect-scrollbar";

const fade = keyframes`
  from: {
    opacity: 0;
  }

  to: {
    opacity: 1
  }
`;

export const Overall = styled.div`
  background: rgba(0, 0, 0, 0.41);
  height: 100vh;
  width: 100vw;
  max-width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  animation: ${fade} 3s ease-in;
`;

export const MainContainer = styled.div`
  background-color: #eff0f3;
  padding: 32px 0;
  height: 100%;
  width: 100%;
  max-width: 721px;
  max-height: 80vh;
  margin: 1.2rem;
  position: relative;
`;
export const ModalContainer = styled(PerfectScrollbar)`
  height: 70% !important;
  width: 100%;
`;

export const LogoWrapper = styled.div`
  display: block;
  text-align: center;
  width: 108px;
  height: 58px;
  margin: 0 auto;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  margin: 1.5rem auto;
  background-color: white;
  padding: 0 5px;
  max-width: 721px;
  height: 40px;
  position: relative;
  justify-content: center;
  margin-bottom: 10px;
`;

export const Button = styled.button`
  width: auto;
  padding: 10px 10px;
  height: 100%;
  white-space: nowrap;
  border-bottom: ${({ theme, focus }) =>
    focus ? `7px solid ${theme.colors.primary}` : "none"};
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Nunito";
  color: ${({ theme }) => theme.colors.secondary};
  font-weight: 200;
  font-size: 1.113rem;

  &:not(:last-child) {
    margin-right: 10px;
  }
`;

export const ChannelSections = styled.div`
  margin: 15px 0;
  padding: 0 45px;
  position: relative;
  width: 100%;
`;

export const SectionTitle = styled.h4`
  width: 100%;
  font-family: "Nunito";
  padding: 27px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
`;

export const Items = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const ChanelPanel = styled.div`
  width: 94px;
  height: 82px;
  border-radius: 9px;
  padding: 10px;
  background-color: ${({ theme }) => theme.colors.secondary};
  margin: 10px;
  text-align: center;
`;

export const Thumb = styled.img`
  width: 100%;
  max-width: 100%;
  height: auto;
`;

export const CloseWrapper = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
`;
