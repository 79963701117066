import React from "react";
import styled from "styled-components";
import media from "styled-media-query";

import { ReactComponent as SelectedIcon } from "assets/img/selected_icon.svg";

import { useGlobalState } from "state/ContextProvider";
import { useLocationState } from "state/LocationProvider";

const Card = ({ children, plan, collapseAllProp, selected }) => {
  const { combo, setCombo } = useGlobalState();
  const { setOnlyMobile, setChoseOnlyMobile } = useLocationState();

  const onClick = (plan) => {
    const exist = combo.some((planSelected) => planSelected.id === plan.id);

    if (exist) {
      return removePlan(plan);
    }

    addOrReplacePlan(plan);
  };

  const addOrReplacePlan = (plan) => {
    let changed = false;

    const newState = combo.map((planSelected) => {
      if (plan.is_tv && planSelected.is_tv) {
        changed = true;
        return plan;
      }

      if (plan.telefonia && planSelected.telefonia) {
        changed = true;
        return plan;
      }

      if (
        plan?.grupos &&
        plan?.grupos === "50" &&
        planSelected?.grupos === "50"
      ) {
        changed = true;
        return plan;
      }

      if (plan.velocidade && planSelected.velocidade) {
        changed = true;
        return plan;
      }

      return planSelected;
    });

    if (changed) {
      const onlyMobile = newState.every(
        (product) => product?.grupos && product?.grupos === "50"
      );
      const haveMobile = newState.some(
        (product) => product?.grupos && product?.grupos === "50"
      );
      setOnlyMobile({
        onlyMobile,
        haveMobile,
      });
      setChoseOnlyMobile(haveMobile && onlyMobile ? true : false);
      return setCombo(newState);
    }

    newState.push(plan);

    const onlyMobile = newState.every(
      (product) => product?.grupos && product?.grupos === "50"
    );

    const haveMobile = newState.some(
      (product) => product?.grupos && product?.grupos === "50"
    );

    setOnlyMobile({
      onlyMobile,
      haveMobile,
    });

    setChoseOnlyMobile(haveMobile && onlyMobile ? true : false);

    setCombo(newState);
  };

  const removePlan = (plan) => {
    if (plan.velocidade) {
      const newState = combo.filter(
        (product) => product?.grupos && product?.grupos === "50"
      );
      setCombo(newState);

      const onlyMobile = newState.every(
        (product) => product?.grupos && product?.grupos === "50"
      );
      const haveMobile = newState.some(
        (product) => product?.grupos && product?.grupos === "50"
      );

      setChoseOnlyMobile(haveMobile && onlyMobile ? true : false);

      return setOnlyMobile({
        onlyMobile,
        haveMobile,
      });
    }

    const newState = combo.filter(
      (planSelected) => planSelected.id !== plan.id
    );

    setCombo(newState);

    setOnlyMobile({
      onlyMobile: newState.every(
        (product) => product?.grupos && product?.grupos === "50"
      ),
      haveMobile: newState.some(
        (product) => product?.grupos && product?.grupos === "50"
      ),
    });
  };

  return (
    <>
      <CardContainer
        collapse={collapseAllProp}
        selected={selected}
        onClick={() => onClick(plan)}
      >
        {selected && <Icon />}
        {children}
      </CardContainer>
    </>
  );
};

export const CardHeader = ({
  title,
  primaryInformation,
  collapseAllProp,
  secondaryInformation,
}) => {
  return (
    <CardHeaderStyle>
      <CardTitle>{title}</CardTitle>
      {!collapseAllProp && (
        <>
          <PrimaryStyle>{primaryInformation}</PrimaryStyle>
          {!!secondaryInformation && (
            <SecondaryStyle>{secondaryInformation}</SecondaryStyle>
          )}
        </>
      )}
    </CardHeaderStyle>
  );
};

export const CardFooter = ({ monthValue, collapseAllProp, onClickDetails, period }) => {
  
  if (collapseAllProp) return null;

  return (
    <CardFooterStyle>
      <MonthValue>
        {monthValue}
        <small>/mês</small>
      </MonthValue>
      <small>por {period} meses</small>

      <Button>Quero Contratar</Button>

      {!!onClickDetails && (
        <ShowDetails onClick={onClickDetails}>Ver detalhes</ShowDetails>
      )}
    </CardFooterStyle>
  );
};

const CardHeaderStyle = styled.div`
  padding: 15px 12px;
  display: flex;
  flex-direction: column;
  border-radius: 9px 9px 0 0;
  align-items: center;
  flex: 1;
`;

const CardFooterStyle = styled.div`
  padding: 15px 12px;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 9px 9px;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.input};

  & small {
    font-size: 0.9rem;
    text-align: center;
    color: ${({ theme, color }) => theme.colors[color || "secondary"]};
    font-family: "Nunito";
    font-weight: 400;
  }
`;

const ShowDetails = styled.a`
  font-size: 0.8rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.secondary};
  font-family: "Nunito";
  font-weight: 400;
  text-decoration: underline;

  margin-top: 1rem;
`;

const Button = styled.div`
  width: 109px;
  height: 30px;
  border-radius: 13px;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  font-size: 22px/27px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  font-family: "VAGRounded BT";
`;

const MonthValue = styled.span`
  font-size: 1.6rem;
  display: block;
  text-align: center;
  color: ${({ theme }) => theme.colors.secondary};
  font-family: "VAGRounded BT";
  white-space: nowrap;
  margin-top: 6px;
  line-height: 1.2;
  word-wrap: unset;
`;

const CardTitle = styled.h2`
  font-family: "VAGRounded BT";
  color: ${({ theme, collapse }) =>
    collapse ? theme.colors.white : theme.colors.secondary};
  text-align: center;
  align-self: stretch;
  word-break: normal;
  white-space: normal;
`;

const PrimaryStyle = styled.h2`
  font-size: 2rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  font-family: "VAGRounded BT";
  margin: 7px 0;
  white-space: normal;
`;

const SecondaryStyle = styled.h2`
  font-size: 14px;
  text-align: center;
  color: ${({ theme }) => theme.colors.secondary};
  font-family: "VAGRounded BT";
  margin-bottom: 3px;
  white-space: normal;
`;

const Icon = styled(SelectedIcon)`
  position: absolute;
  top: 2px;
  right: 2px;
  z-index: 60;
`;

const CardContainer = styled.button`
  min-width: 180px;
  max-width: 220px;
  width: auto;
  min-height: ${({ collapse }) => (collapse ? "auto" : "177px")};
  height: ${({ collapse }) => (collapse ? "auto" : "auto")};
  overflow-y: hidden;
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.primary : theme.colors.white};
  transition: all 0.3s ease;
  margin: 15px;
  position: relative;
  display: flex;
  padding: 0;
  flex-direction: column;
  border-radius: 9px;
  border: 1px solid ${({ theme }) => theme.colors.input};
  align-items: stretch;

  & ${CardTitle} {
    color: ${({ theme, selected }) =>
      selected ? theme.colors.white : theme.colors.secondary};
    font-size: ${({ collapse, selected }) =>
      collapse ? (selected ? "1rem" : "1rem") : "1rem"};
  }

  &:first-child {
    margin-left: 20px;
  }

  &:last-child {
    margin-right: 20px;
  }

  ${media.greaterThan("medium")`
    &:first-child {
      margin-left: 20px;
    }

    &:last-child {
      margin-right: 0;
    }
  `}

  display: flex;
  flex-direction: column;
`;

export default Card;
