import React from "react";
import { withRouter } from "react-router-dom";
import { Route, Switch } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import Header from "components/header/Header.component";

import MountPackage from "views/mount-package/MountPackage.screen";
import PersonalData from "views/personal-data/PersonalData.screen";
import Location from "views/location/Location.screen";
import Protocol from "views/protocol/Protocol.screen";
import NotSupported from "views/not-supported/NotSupported.scren";
import NotAllowed from "views/not-allowed/NotAllowed.screen";

const AppRoutes = withRouter(({ location }) => {
  return (
    <>
      <Header />
      <TransitionGroup>
        <CSSTransition key={location.key} classNames="slide" timeout={1000}>
          <Switch>
            <Route exact path="/" component={PersonalData} />
            <Route exact path="/selecionar-produtos" component={MountPackage} />
            <Route exact path="/localizacao" component={Location} />
            <Route exact path="/protocolo" component={Protocol} />
            <Route exact path="/sem-suporte" component={NotSupported} />
            <Route exact path="/sem-permissao" component={NotAllowed} />
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </>
  );
});

export default AppRoutes;
